import { useEffect, useState } from 'react';
import { Text, View, Pressable, StyleSheet, Dimensions } from 'react-native';
import { Order, PickupBar, Purchase } from '@datamodel';
import { useLanguage } from '@languageProvider';
import { Spacing, Mixins, Typography, Colors, Icon } from '@styles';
import { useTheme } from '@themeProvider';
import { parsePrice, dateToHour, shortTimeDistance, dateToShortString, objectGetAny } from '@utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { PurchaseInfo } from '@molecules';
import { Button } from '@atoms';
import { useModal, useSnackbar } from '@lib';
import { setGrillOrderServingStatus } from '@redux/actions';
import { printIframeAsync } from '@utils/printing';

const { width: dWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
    card: {
        paddingHorizontal: Spacing.MARGINS,
        paddingVertical: Spacing.M,
        ...Mixins.rounding(120)
    },
    grillCard: {
        marginHorizontal: Spacing.XS, marginTop: Spacing.XS
    }
})

export const OrderRow = ({ order=Order.model, style, ...props }) => {
    const { translate } = useLanguage();
    const { useColor } = useTheme();
    const [body, primary, title, label, orderColor] = useColor(['body', 'primary', 'title', 'label', Order.getServingColor(order)]);
    const menus = useSelector(state => state.menus);
    const stackedOrder = Order.stackPurchases(order);
    const groupedPurchases = Purchase.collectionGroupByFirstId(stackedOrder.purchases);

    return <View {...props} style={[styles.card, style]}>
        <Text style={[Typography.OVERLINE, { color: orderColor }]}>{order.servingStatus ? translate(order.servingStatus) : translate('noPreparation')}</Text>
        <View style={Mixins.HORIZONTAL}>
            <View style={{ flex: 1 }}>
                {
                    Order.getShortCode(order).map(shortCode => {
                        return <Text style={[Typography.TITLE, {fontFamily: 'monospace', fontWeight: 'bold', flex: 1, color: title }]}>
                            {shortCode}
                        </Text>
                    })
                }
            </View>
            
            <Text style={[Typography.TITLE, { color: primary }]}>
                { order.total !== order.amount &&
                    <Text style={{ textDecorationLine: 'line-through', color: label }}>{parsePrice(order.amount, '€')}</Text>
                }
                {' ' + parsePrice(order.total, '€')}</Text>
        </View>
        { Object.keys(groupedPurchases).map(gId => {
            const purchases  = groupedPurchases[gId];
            const menuId = objectGetAny(purchases).menuNodeId;
            const menu = menus[menuId] || {};
            return <View style={{ marginTop: Spacing.M}}>
                <Text style={[Typography.OVERLINE, { color: body }]}>{menu[gId]?.name}</Text>
                { Object.keys(purchases).map((k, i) => {
                    const { quantity, ...purchase } = purchases[k];
                    return <PurchaseInfo showArea={!i} {...{ quantity, purchase }} menu={menu} />
                })}
            </View>
        })}
        <Text style={[Typography.SMALL_COPY, { color: body }]}>{dateToHour(order.succeededAt)} - {dateToShortString(order.succeededAt, translate)}</Text>
    </View>
}

export const PurchasesSummary = ({ orders = {}, style, ...props }) => {
    const stackedPurchases = Order.collectionStackPurchases(orders);
    const menus = useSelector(state => state.menus);
    const { useElevation } = useTheme();
    const elevation = useElevation(4);
    const rounding = Mixins.rounding(64);

    return <View style={[Mixins.NO_SELECT, Mixins.HORIZONTAL, {
        paddingHorizontal: Spacing.M,
        paddingVertical: Spacing.XS,
        backgroundColor: elevation.backgroundColor,
        overflow: 'hidden',
    }, rounding, style]}>
        <View style={[{flex: 1 }]}>
            {Object.keys(stackedPurchases).map(selId => {
                const { quantity, ...purchase } = stackedPurchases[selId];
                const menu = menus[purchase.menuNodeId];
                if (!menu) return <></>
                return <PurchaseInfo key={selId} showArea={false} {...{ quantity, purchase, menu }} />
            })}
        </View>
        </View>
}

export const GrillCard = ({ order = Order.model, style, showNextAction, extraActions=[], collapsed=false, ...props }) => {
    const dispatch = useDispatch();
    const { translate } = useLanguage();
    const { pushActionSheet } = useModal();
    const { pushSnack } = useSnackbar();
    const { useColor, useElevation } = useTheme();
    const menus = useSelector(state => state.menus);
    const pickupBarId = useSelector(state => state.authentication.pickupBarId);
    const pickupBar = useSelector(state => state.pickupBars[pickupBarId]);
    const preparationGroups = useSelector(state => state.preparationGroups);
    const [body, title, pressColor, currentColor, line, primary, warning] = useColor(['body', 'title',
        Order.getServingColor({ servingStatus: Order.nextServingStatus(order) }),
        Order.getServingColor(order),
        'line', 'primary', 'warning']);
    const elevation = useElevation(4);
    const [timeString, setTimeString] = useState(shortTimeDistance(order.succeededAt, translate));
    const rounding = Mixins.rounding(64);
    const stackedOrder = Order.stackPurchases(order);

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeString = shortTimeDistance(order.succeededAt, translate);
            if (newTimeString !== timeString) {
                setTimeString(newTimeString);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    // WEB ONLY
    const printOrder = () => {
        try {
            const iframe = document.querySelector('#printf');
            const iwindow = iframe.contentWindow;
            iwindow.document.write(
                Order.asGrillTicketHTML(order, preparationGroups, menus)
            )
            iwindow.document.close();
            printIframeAsync(iframe);
        } catch (error) {
            console.log('PRINT ERROR:', error);
        }
        return true;
    }

    const pressCard = () => {
        const previousState = Order.moveServingStatus(order, -1);

        let actions = [{
            function: printOrder,
            displayText: translate('printGrillTicket'),
        }];


        if (showNextAction && order.servingStatus === Order.SERVING_READY) {
            actions.push({
                function: () => {
                    nextPress();
                    pushSnack({
                        text: translate('orderServed'),
                        type: 'success',
                        icon: 'check-alt'
                    });
                    return true;
                },
                displayText: `${translate('markAsServed')}`
            })
        }

        // if (showNextAction && previousState !== order.servingStatus) {
        //     actions.push({
        //         function: () => {
        //             dispatch(setGrillOrderServingStatus(pickupBarId, {
        //                 orderId: order.orderId,
        //                 servingStatus: previousState,
        //                 currentStatus: order.servingStatus,
        //                 back: true,
        //             }))
        //             return true;
        //         },
        //         displayText: `${translate('moveTo')} ${translate(previousState)}`,
        //         type: 'destructive'
        //     })
        // }

        pushActionSheet({
            children: <View style={{ paddingHorizontal: Spacing.MARGINS, paddingVertical: Spacing.S }}>
                <Text style={[Typography.H6, { fontFamily: 'monospace', color: title, textAlign: 'center', marginBottom: Spacing.XS }]}>{Order.getShortCode(order).map(shortCode => `${shortCode}`).join(' ')}</Text>
                {Object.keys(stackedOrder.purchases).map(selId => {
                    const { quantity, ...purchase } = stackedOrder.purchases[selId];
                    return <PurchaseInfo showArea={false} {...{ quantity, purchase, menu: menus[purchase.menuNodeId] }} />
                })}
            </View>,
            actions: [...actions, ...extraActions]
        }, {
            style: {
                paddingHorizontal: dWidth * .3
            }
        })
    }

    const nextPress = () => {
        dispatch(setGrillOrderServingStatus(pickupBarId, {
            orderId: order.orderId,
            servingStatus: Order.nextServingStatus(order),
            currentStatus: order.servingStatus
        }))


        // if (order.servingStatus === Order.SERVING_PREPARING && pickupBar.printKitchenTickets !== 'never') {
        //     printOrder();
        // }

        return true;
    }

    return <View style={[Mixins.NO_SELECT, Mixins.HORIZONTAL, elevation, {
            paddingLeft: Spacing.M,
            paddingRight: Spacing.XXS,
            paddingVertical: Spacing.XS,
            overflow: 'hidden',
            backgroundColor: order.serviceType === 'delivery' ? Colors.alphaBlend(warning, elevation.backgroundColor, .05) : elevation.backgroundColor,
            borderColor: warning,
            }, rounding, style]}>
        <Pressable onPress={pressCard} style={{ flex: 1, marginRight: showNextAction && order.servingStatus !== Order.SERVING_READY ? 64 : 0 }}>

            {!!order.note &&
                <View style={{ borderBottomWidth: 1, borderColor: line, paddingVertical: Spacing.XS, }}>
                    <Text style={[Typography.SUBTITLE, { color: body }]}>{order.note}</Text>
                </View>
            }

            <View style={[Mixins.HORIZONTAL]}>
                <Text style={[Typography.TITLE, { color: title, flex: 1 }]}>{translate('order')} <Text style={[{ color: currentColor }]}>{Order.getShortCode(order).map(shortCode => `#${shortCode}`).join(' ')}</Text></Text>
                <Icon name="more-md" color={body} size={24} style={{marginLeft: Spacing.M}}/>
            </View>
            <View style={[Mixins.HORIZONTAL, { alignItems: 'center', marginTop: Spacing.XXS }]}>
                <Icon name="clock" size={Typography.SMALL_COPY.lineHeight} color={body} />
                <Text style={[Typography.SMALL_COPY, { color: body, marginLeft: Spacing.XXS, }]}>{timeString} {!order.email &&
                    <Text style={{ fontWeight: 'bold', marginLeft: Spacing.XXS, paddingHorizontal:Spacing.XXS, paddingVertical: 2, borderRadius: 2, color: warning, backgroundColor: Colors.setOpacity(warning,.15) }}>{translate('ticket')}</Text>}</Text>
            </View>
            
            { collapsed ? 
                <View style={{ paddingBottom: Spacing.XS }}/> :
                <View style={{ paddingVertical: Spacing.XS }}>

                    {Object.keys(stackedOrder.purchases).map(selId => {
                        const { quantity, ...purchase } = stackedOrder.purchases[selId];
                        const menu = menus[purchase.menuNodeId];
                        if (!menu) return <></>
                        return <PurchaseInfo key={selId} showArea={false} {...{ quantity, purchase, menu }} />
                    })}

                </View>
            }
        </Pressable>
        { showNextAction && order.servingStatus !== Order.SERVING_READY && <Pressable onPress={nextPress} style={{
            width: 64,
            position: 'absolute',
            right: Spacing.M,
            top: 0,
            bottom: 0,
            backgroundColor: Colors.setOpacity(pressColor, .25),
            marginTop: -Spacing.XS,
            marginRight: -Spacing.M,
            paddingRight: Spacing.XS,
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Icon name="back-arrow-ios" style={{ transform: [{ rotate: '180deg' }] }} size={24} color={pressColor} />
        </Pressable>}

    </View>
}
GrillCard.ROUNDING =  Mixins.rounding(64);
GrillCard.ELEVATION = 4;

export const OrderBatch = ({ batchOrders, preparationGroups, menus, markOrdersAsReady, style, preparingColumnWidth='60%',...props }) => {
    const { translate } = useLanguage();
    const { useColor } = useTheme();
    const [ line, background ] = useColor(['line', 'background'])
    
    const printOrder = async (order, preparationGroups, menus) => {
        try {
            // screenTracker.printTicket('GrillTicket', {
            //     order: order.orderId,
            // })

            const ticket = Order.asGrillTicketHTML(order, preparationGroups, menus);


            if (!ticket) return;

            const iframe = document.querySelector('#printf');
            const iwindow = iframe.contentWindow;

            iwindow.document.write(ticket)
            iwindow.document.close();
            await printIframeAsync(iframe);
        } catch (error) {
            console.log('PRINT ERROR:', error);
        }
        return true;
    }


    return <View>
        <View style={[Mixins.HORIZONTAL, { marginBottom: Spacing.XS }]}>
            <View style={{ marginRight: Spacing.XS, width: preparingColumnWidth }}>

                {Object.keys(batchOrders).map(orderId => {
                    return <GrillCard
                        key={orderId}
                        order={batchOrders[orderId]}
                        style={{ marginBottom: Spacing.XS }}
                        showNextAction
                    />
                })}
            </View>
            <View style={{ flex: 1 }}>
                <PurchasesSummary orders={batchOrders} />
                <Button onPress={() => {
                    // Printing
                    (async () => {
                        for (const orderId in batchOrders) {
                            const order = batchOrders[orderId];
                            await printOrder(order, preparationGroups, menus);
                        }
                    })();
                }} type="secondary" color="secondary" title={translate("print")} style={{ marginTop: Spacing.XS }} />
                <Button onPress={() => markOrdersAsReady(batchOrders)} type="primary" color="success" icon="check-alt" title={translate('batchReady')} style={{ marginTop: Spacing.XS }} />
            </View>
        </View>
        {/* Separator */}
        <View style={{
            borderTopWidth: 1,
            borderColor: line,
            alignItems: 'center',
            marginBottom: Spacing.M
        }}>
            <View style={{ position: 'absolute', left: 0, right: 0, height: 1, backgroundColor: background }} />
        </View>
    </View>
}