import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { TextInput as RNTextInput, Pressable, StyleSheet, Text, Animated, View, ActivityIndicator, Dimensions, Platform } from 'react-native';
import Animation from '@utils/animations';
import PropTypes from 'prop-types';
import { useTheme } from '@themeProvider';
import { Mixins, Colors, Spacing, Typography, Icon } from '@styles';
import { useLanguage } from '@languageProvider';
import { Button } from '@atoms';
import { useModal } from '@lib';
// import { DateTimePicker } from '@atoms';
import { getDayLetter, dateToISO8641, dateToShortString, dateToHour } from '@utils/helpers';

const {
    height: deviceHeight,
    width: deviceWidth
} = Dimensions.get('window');

const getState = (value, errored, focused) => {
    if (value !== '' && errored) return 'error';
    if (value === '' && !focused) return 'default';
    if (value !== '' && !focused) return 'filled';
    return 'focused';
}

const getAnimationState = (value, focused) => {
    if (!focused) {
        if (value !== '') return 2;
        return 0;
    }
    if (value === '') return 0;
    return 1;
}

export const TextInput = forwardRef(({
    style,
    textStyle,
    information = '',
    errored,
    value = '', setValue,
    label: labelText,
    centered,
    loadFunc,
    validations,
    secureTextEntry,
    forcedTheme,
    scrollToInput,
    optional = false,
    prefix,
    ...props }, ref) => {
    const input = useRef();
    const animation = useRef(new Animated.Value(getAnimationState(value, input.current && input.current.isFocused()))).current;
    const loadingAnimation = useRef(new Animated.Value(0)).current;
    const [state, setState] = useState(getState(value, errored, input.current && input.current.isFocused()));
    const { useColor, isDark, useElevation } = useTheme();
    const { translate } = useLanguage();
    const [securedEntry, setSecuredEntry] = useState(secureTextEntry);
    const [backgroundColor, body, line, inputBackground, title, label, placeholder, primary, error, success] = useColor([
        'background',
        'body',
        'line',
        'inputBackground',
        'title',
        'label',
        'placeholder',
        'primary',
        'error',
        'success'
    ], { forcedTheme });

    useImperativeHandle(ref, () => ({
        focus: () => {
            input.current.focus();
        }
    }))

    useEffect(() => {
        const newState = getState(value, errored, input.current && input.current.isFocused());
        if (newState !== 'default' && newState !== state) {
            Animated.timing(animation, Animation.short(1)).start();
        }
        setState(newState);
    }, [value, errored]);

    const focusedBackground = useElevation(12, { forcedTheme }).backgroundColor;

    const onFocus = () => {

        if (scrollToInput) {
            setTimeout(() => {
                input.current.measureInWindow((x, y, width, height) => {
                    scrollToInput({ x, y, width, height })
                })
            }, 100)
        }

        setState(getState(value, errored, true));
        if (value !== '')
            Animated.timing(animation, Animation.short(1)).start();
    }

    const onChangeText = (text) => {
        if ((value === '' && text !== '') || (value !== '' && text === '')) {
            setState(getState(text, errored, input.current && input.current.isFocused()));
            if (text === '') {
                Animated.timing(animation, Animation.short(0)).start();
            } else {
                Animated.timing(animation, Animation.short(1)).start();
            }
        }

        if (text !== '' && loadFunc) {
            Animated.timing(loadingAnimation, Animation.short(1)).start();
            (async () => {
                await loadFunc(text);
                Animated.timing(loadingAnimation, Animation.short(0)).start();
            })();
        }

        setValue(text);
    }

    const onBlur = () => {
        setState(getState(value, errored, false));
        if (value === '')
            Animated.timing(animation, Animation.short(0)).start();
    }

    const getStyle = () => {
        switch (state) {
            case 'error':
                return {
                    borderWidth: 1,
                    borderColor: error,
                    color: error,
                    backgroundColor: inputBackground
                }
            case 'focused':
                return {
                    borderWidth: 1,
                    borderColor: primary,
                    color: title,
                    backgroundColor: focusedBackground
                }
            case 'filled':
                return {
                    borderWidth: 1,
                    borderColor: line,
                    color: body,
                    backgroundColor: inputBackground,
                }

            case 'default':
            default:
                return {
                    borderWidth: 1,
                    borderColor: line,
                    color: placeholder,
                    backgroundColor: inputBackground,
                }
        }
    }

    // TODO: Change paddingLeft to be dependent on centered input

    return (
        <View style={style}>

            {labelText !== '' && (state !== 'default' && value.length > 0) &&
                <Animated.Text
                    style={{
                        color: animation.interpolate(Animation.keyframes(placeholder, title, label)),
                        opacity: animation.interpolate(Animation.keyframes(0, 1, 1)),
                        paddingLeft: centered ? 0 : Spacing.S,
                        textAlign: centered ? 'center' : 'left',
                        left: 0,
                        right: 0,
                        ...Typography.OVERLINE,
                    }}>{labelText}</Animated.Text>

            }

            <View>
                {state === 'focused' && <View style={[styles.overlay, { backgroundColor: Colors.setOpacity(primary, isDark ? .5 : .3) }]} />}
                {state === 'error' && <View style={[styles.overlay, { backgroundColor: Colors.setOpacity(error, isDark ? .5 : .3) }]} />}

                <View style={[
                    Mixins.HORIZONTAL,
                    styles.container,
                    getStyle(),
                    (prefix || centered) ? { paddingLeft: 0 } : {},
                    props.multiline ? { height: undefined, minHeight: TextInput.HEIGHT, paddingVertical: Spacing.XS, paddingHorizontal: Spacing.M } : {},
                ]}>
                    {!!prefix &&
                        <View
                            style={{
                                justifyContent: 'center',
                                backgroundColor: Colors.setOpacity(line, .3),
                                paddingHorizontal: typeof prefix === 'string' ? Spacing.M : 0,
                                marginRight: Spacing.S,
                                borderRightWidth: 1,
                                borderColor: line

                            }}>
                            {typeof prefix === 'string' ?
                                <Text style={[Typography.TITLE, { color: label }]}>{prefix}</Text>
                                :
                                prefix
                            }
                        </View>
                    }

                    <RNTextInput
                        {...props}
                        ref={input}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChangeText={onChangeText}
                        value={value}
                        placeholder={
                            labelText ?
                            labelText + (optional ? ` (${translate('optional')})` : '')
                            : props.placeholder
                        }
                        placeholderTextColor={placeholder}
                        textAlign={centered ? 'center' : 'left'}
                        style={[
                            Typography.BODY,
                            { flex: 1 },
                            getStyle(),
                            { borderWidth: 0 },
                            centered ? { paddingLeft: 0 } : {},
                            textStyle,
                        ]}
                        secureTextEntry={securedEntry}
                    />
                    
                    {loadFunc &&
                        <Animated.View style={{
                            position: 'absolute',
                            right: Spacing.XS,
                            top: 0,
                            bottom: 0,
                            justifyContent: 'center',
                            transform: [{ scale: loadingAnimation }],
                            // maxWidth: loadingAnimation.interpolate(Animation.keyframes(0, 48)),
                            overflow: 'hidden'
                        }}>
                            <ActivityIndicator color={primary} animating={true} />
                        </Animated.View>
                    }
                </View>

                {secureTextEntry &&
                    <Pressable onPress={() => setSecuredEntry(!securedEntry)} style={{ position: 'absolute', top: 0, bottom: 0, right: Spacing.XS, justifyContent: 'center' }}>
                        <Icon name={securedEntry ? "eye" : "eye-off"} color={primary} size={24} />
                    </Pressable>
                }
            </View>
            {validations && input.current && input.current.isFocused() &&
                <>
                    {validations
                        .filter(v => !v.status || (v.status && !v.onlyOnError))
                        .map((v, i) =>
                            <View style={styles.informationContainer} key={i}>
                                <Icon size={20} name={v.status ? 'check' : 'close'} color={v.status ? success : error} style={{ marginRight: Spacing.XXS }} />
                                <Text style={[Typography.SMALL_COPY, {
                                    flex: 1,
                                    color: body
                                }]}>{v.str}</Text>
                            </View>
                        )}
                </>

            }

            {information !== '' &&
                <View style={styles.informationContainer}>
                    <Icon size={20} name={'info'} color={errored ? error : body} style={{ marginRight: Spacing.XXS }} />
                    <Text style={[Typography.SMALL_COPY, {
                        color: body
                    }]}>{information}</Text>
                </View>
            }
        </View>
    )

})

TextInput.HEIGHT = 44;
TextInput.ROUNDING = Mixins.rounding(TextInput.HEIGHT).borderRadius;
TextInput.TOPMARGIN = Typography.OVERLINE.lineHeight + Spacing.M;

TextInput.propTypes = {
    /**
     * This is the text the TextInput will display
     */
    value: PropTypes.string,
    /**
     * The setter of the value called when onChangeText
     */
    setValue: PropTypes.func,
    /**
     * The label of the TextInput
     */
    label: PropTypes.string,
    /**
     *  Information to be displayed at the bottom of the TextInput
     */
    information: PropTypes.string,
    /**
     *  Wether the field contains an error or not
     */
    errored: PropTypes.bool,
    /**
     *  True if text is centered
     */
    centered: PropTypes.bool,
}

TextInput.defaultProps = {
    value: '',
    setValue: Function.prototype(),
    label: '',
    information: '',
    errored: false,
    centered: false,
}



export const Stepper = ({ value, setValue, color, style, size, min=1, max=Infinity, ...props }) => {
    const { useColor, isDark, useElevation } = useTheme();
    const title = useColor('title');

    const decrease = () => {
        if (value > min) {
            setValue(value - 1)
        }
    }

    const increase = () => {
        if (value < max) {
            setValue(value + 1)
        }
    }

    return (
        <View {...props} style={[Mixins.HORIZONTAL, { justifyContent: 'center', alignItems: 'center' }, style]}>
            <Button size={size} onPress={decrease} icon="minus-alt" type="transparent" color={color} status={value === min ? "disabled" : "default"} style={{ width: Button.HEIGHT, paddingHorizontal: 0 }} />
            <Text style={[Typography.TITLE, { textAlign: 'center', color: title, marginHorizontal: Spacing.S, minWidth: 24 }]}>{value}</Text>
            <Button size={size} onPress={increase} icon="add-alt" type="filled" color={color} status={value === max ? "disabled" : "default"} style={{ width: Button.HEIGHT, paddingHorizontal: 0 }} />
        </View>
    )
}


/**
 * Selectable Inputs - Radio, Checkbox and Switch
 */

const SelectableLabel = ({ label: labelText, checked, disabled }) => {
    const { useColor } = useTheme();
    const [label, body, placeholder] = useColor(['label', 'body', 'placeholder']);

    return (
        <Text style={[Typography.BODY, { color: disabled ? placeholder : checked ? body : label }]}>{labelText}</Text>
    )
}

export const RadioButton = ({ label, checked, onPress, style, ...props }) => {

    const { useColor, isDark, useElevation } = useTheme();
    const [line, inputBackground, primary] = useColor([
        'line',
        'inputBackground',
        'primary',
    ]);

    const getStyle = () => checked ? {
        backgroundColor: primary,
    } : {
        backgroundColor: props.disabled ? line : inputBackground,
        borderColor: line,
        borderWidth: 1,
    };

    return (
        <Pressable {...props} onPress={onPress} style={[styles.selectableContainer, style]}>
            <View style={[styles.radio, getStyle()]}>
                <View style={{ backgroundColor: props.disabled ? 'white' : inputBackground, width: 12, height: 12, borderRadius: 6, opacity: checked ? 1 : 0 }} />
            </View>
            <SelectableLabel label={label} checked={checked} disabled={props.disabled}/>
            {props.children}
        </Pressable>
    )
}

export const Checkbox = ({ label, checked, onPress, style, ...props }) => {

    const { useColor, isDark, useElevation } = useTheme();
    const [line, inputBackground, primary, placeholder] = useColor([
        'line',
        'inputBackground',
        'primary',
        'placeholder'
    ]);

    const getStyle = () => props.disabled ? {
        backgroundColor: line,
    } : checked ? {
        backgroundColor: primary,
    } : {
        backgroundColor: inputBackground,
        borderColor: line,
        borderWidth: 1,
    };

    return (
        <Pressable {...props} onPress={onPress} style={[styles.selectableContainer, style]}>
            <View style={[styles.checkbox, getStyle()]}>
                {
                    checked &&
                    <Icon color={props.disabled ? 'white' : inputBackground} size={20} name="check-alt" />
                }
            </View>
            <SelectableLabel label={label} checked={checked} disabled={props.disabled}/>
            {props.children}
        </Pressable>
    )
}

export const Switch = ({ label, checked, onPress, style, color, ...props }) => {
    const animation = useRef(new Animated.Value(checked ? 1 : 0)).current;
    const { useColor, isDark, useElevation } = useTheme();
    const [line, inputBackground, primary, placeholder] = useColor([
        'line',
        'inputBackground',
        'primary',
        'placeholder'
    ]);

    const elevation = useElevation(12);
    // const unselectedColor = Colors.festoColors.onDark.body;
    // const drinkColor = Colors.festoColors.onDark.secondary;
    // const ticketColor = Colors.festoColors.onDark.primary;

    useEffect(() => {
        if (checked) {
            animation.setValue(1);
        } else {
            animation.setValue(0);
        }
    }, [checked])

    const toggle = () => {
        if (animation.__getValue()) {
            Animated.timing(animation, Animation.short(0)).start(({ finished }) => {
                if (finished) {
                    onPress();
                }
            });
        } else {
            Animated.timing(animation, Animation.short(1)).start(({ finished }) => {
                if (finished) {
                    onPress();
                }
            });
        }
    }

    return (
        <Pressable {...props} onPress={toggle} style={[styles.selectableContainer, style]}>

            <Animated.View
                style={{
                    justifyContent: 'center',
                    borderWidth: animation.interpolate(Animation.keyframes(1, 0)),
                    borderColor: line,
                    borderRadius: 12,
                    height: 28,
                    width: 52,
                    padding: 4,
                    marginRight: Spacing.S,
                    backgroundColor: animation.interpolate(Animation.keyframes(inputBackground, primary))
                }}
            >
                <Animated.View
                    style={{
                        backgroundColor: 'white',
                        width: 20,
                        height: 20,
                        borderRadius: 8,
                        backgroundColor: animation.interpolate(Animation.keyframes(placeholder, elevation.backgroundColor)),
                        transform: [{ translateX: animation.interpolate(Animation.keyframes(0, 24)) }]
                    }}
                />
            </Animated.View>
            <SelectableLabel label={label} checked={checked} disabled={props.disabled}/>
            {props.children}
        </Pressable>
    )
}



/**
 * 
 * @param {*} style - Styling of the component
 * @param {*} state - Array of booleans, representing if the day is selected or not. 0 is sunday.
 * @param {*} setState - Setter of the state
 */
export const WeekdayInput = ({ style, state, setState }) => {
    const { useColor, isDark } = useTheme();
    const { translate } = useLanguage();
    const [primary, label, inputBackground] = useColor(['primary', 'label', 'inputBackground']);
    const [text] = useColor([
        ['title', { on: primary }]
    ])

    const toggleDay = (day) => {
        setState(state.map((d, index) => index === day ? !d : d));
    }

    return (
        <View style={[Mixins.HORIZONTAL, { justifyContent: 'space-between' }, style]}>
            {[1, 2, 3, 4, 5, 6, 0].map(dayNumber => (
                <Pressable key={dayNumber}
                    style={[styles.weekdayButton, {
                        backgroundColor: state[dayNumber] ? Colors.setOpacity(primary, 0.25) : inputBackground
                    }]}
                    onPress={() => toggleDay(dayNumber)}
                >
                    <Text style={[Typography.TITLE, { color: state[dayNumber] ? primary : label }]} >{getDayLetter(dayNumber, translate)}</Text>
                </Pressable>
            ))}
        </View>
    )
}

export const TabInput = ({ options, selected, setSelected, style, ...props }) => {
    const [width, setWidth] = useState(1);
    const animation = useRef(new Animated.Value(selected)).current;
    const { useColor, useElevation } = useTheme();
    const [line, backgroundColor, title, placeholder] = useColor(['line', 'background', 'title', 'placeholder']);
    const elevation = useElevation(4);
    const pressTab = (i) => {
        Animated.timing(animation, Animation.short(i, 'pol5', { v0: 1.9 })).start(({ finished }) => {
            if (finished) {
                setSelected(i);
            }
        })
    }

    useEffect(() => {
        if (animation.__getValue() !== selected) {
            Animated.timing(animation, Animation.short(selected, 'pol5', { v0: 1.9 })).start();
        }
    }, [selected])

    return (
        <View style={[styles.tabInputContainer, { borderColor: line, backgroundColor }, style]}>
            {
                options.length > 1 &&
                <>
                    <Animated.View
                        style={{
                            flexGrow: 1,
                            ...elevation,
                            marginVertical: 4,
                            marginLeft: animation.interpolate(Animation.keyframes(...options.map((_, i) => i * width + 4))),
                            marginRight: animation.interpolate(Animation.keyframes(...options.map((_, i) => (options.length - 1 - i) * width + 4))),
                            ...Mixins.matchRounding(Mixins.rounding(TabInput.HEIGHT), -4)
                        }}>

                    </Animated.View>

                    <View
                        onLayout={({ nativeEvent }) => {
                            setWidth(nativeEvent.layout.width / options.length);
                        }}
                        style={[StyleSheet.absoluteFill, Mixins.HORIZONTAL, { elevation: 5 }]}>

                        {options.map((o, i) => (
                            <Pressable onPress={() => pressTab(i)} key={i} style={[{ flexGrow: 1, justifyContent: 'center' }]}>
                                <Animated.Text style={[
                                    Typography.BODY,
                                    {
                                        textAlign: 'center',
                                        color: selected === i ? title : placeholder,
                                    }
                                ]}>{o}</Animated.Text>
                            </Pressable>
                        ))}
                    </View>
                </>
            }
            {
                options.length === 1 &&
                <>
                    <View
                        style={{
                            flexGrow: 1,
                            ...elevation,
                            marginVertical: 4,
                            marginHorizontal: 4,
                            ...Mixins.matchRounding(Mixins.rounding(TabInput.HEIGHT), -4),
                            justifyContent: 'center'
                        }}>
                        <Text style={[
                            Typography.BODY,
                            {
                                textAlign: 'center',
                                color: title,
                            }
                        ]}>{options[0]}</Text>

                    </View>
                </>
            }

        </View>
    )
}

TabInput.HEIGHT = 48;

// const TimeInputNative = ({ style, label, setTime, timeVal = new Date(), ...props }) => {
//     const [show, setShow] = useState(false);
//     const onChange = (_, selectedTime) => {
//         setTime(selectedTime || timeVal)
//     }
//     return (
//         <>
//             <Pressable style={style} onPress={() => setShow(true)}>
//                 <TextInput pointerEvents="none" editable={false} disabled label={label} value={dateToHour(timeVal)} />
//             </Pressable>
//             {show && <DateTimePicker
//                 mode="time"
//                 value={timeVal || new Date()}
//                 onChange={onChange}
//                 visible={show}
//                 setVisible={setShow}
//             />}
//         </>
//     )
// }

// const TimeInputWeb = ({ style, label, setTime, timeVal = new Date(), ...props }) => {
//     const { translate } = useLanguage();
//     const onChange = ({ target }) => {
//         setTime(new Date(target.value))
//     }

//     return (
//         <Pressable style={style}>
//                 <TextInput pointerEvents="none" editable={false} disabled label={label} value={dateToHour(timeVal)} />
//                 <input
//                     onChange={onChange}
//                     style={{ width: '100%', opacity: 0, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
//                     type="time" />
//         </Pressable>
//     )
// }

// export const TimeInput = Platform.select({
//     native: TimeInputNative,
//     web: TimeInputWeb,
// })


// const DateInputNative = ({ style, label, setDate, date = new Date(), maximumDate, ...props }) => {
//     const { translate } = useLanguage();
//     const [show, setShow] = useState(false);
//     const onChange = (_, selectedDate) => {
//         setDate(selectedDate || date)
//     }
//     return (
//         <>
//             <Pressable style={style} onPress={() => setShow(true)}>
//                 <TextInput pointerEvents="none" editable={false} disabled label={label} value={date ? dateToShortString(date, translate) : ''} />
//             </Pressable>
//             {show && <DateTimePicker
//                 mode="date"
//                 maximumDate={maximumDate}
//                 value={date || new Date()}
//                 onChange={onChange}
//                 visible={show}
//                 setVisible={setShow}
//             />}
//         </>
//     )
// }

// const DateInputWeb = ({ style, label, setDate, date = new Date(), maximumDate, ...props}) => {
//     const { translate } = useLanguage();
//     const onChange = ({target}) => {
//         setDate(new Date(target.value))
//     }

//     return (
//         <Pressable style={style}>
//             <TextInput pointerEvents="none" editable={false} disabled label={label} value={date ? dateToShortString(date, translate) : ''} />
//             <input 
//                 max={maximumDate ? dateToISO8641(maximumDate) : undefined }
//                 onChange={onChange}
//                 style={{ width: '100%', opacity: 0,position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}
//                 type="date"/>
//         </Pressable>
//     )
// }

// export const DateInput = Platform.select({
//     native: DateInputNative,
//     web: DateInputWeb,
// })


// FOR SOME REASON IMPORTING PickerModal from @organisms does not work here, but it does in home/sections WTFFF

/**
 * 
 * Options are objects with the shape:
 * {
 *  key: '',
 *  text: '',
 * }
 * Keys are used to know which input is selected, text is what's displayed to the user
 */
const PickerModal = ({ _dismissModal, setValue, options, style, ...props }) => {
    const animation = useRef(new Animated.Value(0)).current;
    const { useElevation, useColor } = useTheme();
    const elevation = useElevation(12);
    const title = useColor('title');

    const select = (key) => {
        setValue(key);
        _dismissModal();
    }

    useEffect(() => {
        Animated.timing(animation, Animation.create(1, 100)).start();
    }, [])

    return (
        <Animated.View style={[styles.card, elevation, { opacity: animation, transform: [{ scale: animation.interpolate(Animation.keyframes(.8, 1)) }] }, style]} {...props}>
            {
                Object.keys(options).map(key => {

                    return <Pressable
                        onPress={() => select(key)}
                        style={{ padding: Spacing.M }} key={key}>
                        <Text style={[Typography.BODY, { color: title }]}>{options[key].text}</Text>
                    </Pressable>
                })
            }
        </Animated.View>
    )
}

export const PickerInput = ({ style, label, options={}, value, setValue, ...props }) => {
    const { pushModal } = useModal();

    const showOptions = () => {
        // Show modal
        pushModal({
            children: React.createElement(PickerModal, { options, setValue })
        })
    }
    return (
        <Pressable style={style} onPress={showOptions}>
            <TextInput pointerEvents="none" editable={false} disabled label={label} value={value in options ? options[value].text : ''} />
        </Pressable>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingLeft: Spacing.S,
        height: TextInput.HEIGHT,
        overflow: 'hidden',
        ...Mixins.rounding(TextInput.HEIGHT),
        ...Typography.BODY,
    },
    overlay: {
        ...Mixins.matchRounding(Mixins.rounding(TextInput.HEIGHT), 2),
        position: 'absolute',
        left: -2,
        right: -2,
        bottom: -2,
        top: -2,
    },
    informationContainer: {
        ...Mixins.HORIZONTAL,
        paddingLeft: Spacing.XS,
        alignItems: 'flex-start',
        marginTop: Spacing.XXS
    },
    selectableContainer: {
        ...Mixins.HORIZONTAL,
        alignItems: 'center',
    },
    radio: {
        width: 24,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 12,
        marginRight: Spacing.S,
    },
    checkbox: {
        width: 24,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center',
        ...Mixins.rounding(24),
        marginRight: Spacing.S,
    },
    tabInputContainer: {
        height: TabInput.HEIGHT,
        ...Mixins.rounding(TabInput.HEIGHT),
        borderWidth: 1,
    },
    weekdayButton: {
        height: 32,
        width: 32,
        ...Mixins.rounding(32),
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        paddingVertical: Spacing.M,
        paddingHorizontal: Spacing.MARGINS,
        // marginHorizontal: Spacing.MARGINS,
        ...Mixins.rounding(deviceWidth - 2 * Spacing.MARGINS),
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
})
