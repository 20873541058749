import React, { useState, useMemo, useRef, useEffect } from 'react';
import { ScrollView, StyleSheet, Text, View, Animated, Dimensions, Pressable, Image, FlatList } from 'react-native';
import { Typography, Mixins, Icon, Spacing, Colors } from '@styles';

import { Stepper, RadioButton, Checkbox, Button, Switch } from '@atoms';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import { SelectionTree, Promotion, ProductNode } from '@datamodel';
import Animation from '@utils/animations';
import { parsePrice } from '@utils/helpers';
import { useSnackbar, _pushModal, _pushSnack } from '@lib';
import { ProductNodeCard } from '@molecules';
import { useDispatch } from 'react-redux';
import { PickupBars } from '@services';

const { height: dHeight, width: dWidth } = Dimensions.get('window');


import { Trackings } from '@services';
const modalTracker = new Trackings.Tracker('ChangeStockModal');

const Question = ({ switchPress, stockState, style }) => {
    const { translate } = useLanguage();
    const { useColor } = useTheme();
    const [title, body, label, primary, error] = useColor(['title', 'body', 'label', 'primary', 'error']);
    
    return (
        <View style={style}>
            <View style={[Mixins.HORIZONTAL, {
                justifyContent: 'space-between',
                paddingHorizontal: Spacing.MARGINS,
                paddingVertical: Spacing.XS,
                marginBottom: Spacing.M,
                backgroundColor: Colors.setOpacity(title, .05)
            }]}>
                <Text style={[Typography.TITLE, {
                    color: title,
                }]}>{stockState.name}</Text>
            </View>
            
            <FlatList
                style={{ marginHorizontal: Spacing.MARGINS }}
                data={Object.keys(stockState.children).sort((n1, n2) => {
                    const o1 = stockState.children[n1].order;
                    const o2 = stockState.children[n2].order;
                    return o1 - o2;
                })}
                extraData={Object.keys(stockState.children).map(id => stockState.children[id].price.outOfStock)}
                key={item => item}
                numColumns={2}
                renderItem={({ item: nodeId, index }) => {
                    const product = stockState.children[nodeId];
                    return <View key={nodeId} style={[Mixins.HORIZONTAL, {
                        flexGrow: 1,
                        maxWidth: '50%',
                        paddingLeft: index % 2 ? Spacing.M : 0, paddingRight: index % 2 ? 0 : Spacing.M,
                        paddingBottom: Spacing.M, alignItems: 'center', justifyContent: 'space-between' }]}>
                        <View style={[Mixins.HORIZONTAL, { alignItems: 'center' }]}>
                            <Image resizeMode='contain' style={[Mixins.rounding(64), { width: 64, height: 64 }]} source={{ uri: product.photo }} />
                            <Text style={[Typography.TITLE, { color: title, marginLeft: Spacing.M }]}>{product.name}</Text>
                        </View>
                        <Switch label={translate('inStock') }checked={!product.price.outOfStock} onPress={() => {
                            switchPress({ nodeId, value: !product.price.outOfStock })
                        }} />
                    </View>
                }}
            />
        </View>
    )
}

export const ChangeStockModal = ({ _dismissModal, onDismiss, selectionTree, productTree, mainSelection, style }) => {
    const { translate } = useLanguage();
    const { useElevation, useColor } = useTheme();
    const { pushSnack } = useSnackbar();

    const animation = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(animation, Animation.create(1, 100)).start();

        return onDismiss;
    }, [])

    const [title, line, body, secondary] = useColor(['title', 'line', 'body', 'secondary']);
    const elevation = useElevation(12);

    const questions = SelectionTree.calculateQuestions(selectionTree, { keepQuestions: false });

    const [stockState, setStockState] = useState(
        ProductNode.getTreeNodes(productTree, new Set([...questions, productTree.nodeId ]))
    );

    const switchPress = async ({ nodeId, value }) => {
        modalTracker.tap('StockSwitch', {
            product_definition: nodeId,
            value: value + ''
        });       
        try {
            await PickupBars.updateProductDefinition(undefined, nodeId, {
                "out_of_stock": value
            });
            ProductNode.findAndReplace(productTree, nodeId, {
                transform: n => ({
                    ...n,
                    price: {
                        ...n.price,
                        outOfStock: value
                    }
                }),
            })
            setStockState(ProductNode.getTreeNodes(productTree, questions));
            pushSnack({
                type: 'success',
                icon: 'check-alt',
                text: translate('stockEdited')
            })
            
        } catch (error) {
            pushSnack({
                type: 'warning',
                icon: 'warning',
                text: translate('somethingWentWrong')
            })
        }
    }
    
    return (
        <Animated.View style={[style, {
            opacity: animation,
            transform: [{ scale: animation.interpolate(Animation.keyframes(.8, 1)) }],
            overflow: 'hidden',
            backgroundColor: elevation.backgroundColor,
            borderRadius: Button.RADIUS,
        }]}>
            
            <Text style={[Typography.H6, { color: title, marginVertical: Spacing.M, textAlign: 'center' }]}>
                {translate('editStock')}
            </Text>

            <View style={[Mixins.HORIZONTAL, { marginHorizontal: Spacing.MARGINS, paddingBottom: Spacing.M,  alignItems: 'center', justifyContent: 'space-between' }]}>
                <View style={[Mixins.HORIZONTAL, { alignItems: 'center' }]}>
                    <Image resizeMode='contain' style={[Mixins.rounding(64), { width: 64, height: 64 }]} source={{ uri: stockState[productTree.nodeId]?.photo }}/>
                    <Text style={[Typography.TITLE, { color: title, marginLeft: Spacing.M }]}>{stockState[productTree.nodeId]?.name}</Text>
                </View>
                <Switch label={translate('inStock')} checked={!stockState[productTree.nodeId]?.price.outOfStock} onPress={() => {
                    switchPress({ nodeId: productTree.nodeId, value: !stockState[productTree.nodeId]?.price.outOfStock })
                }}/>
            </View>

            <ScrollView
                bounces={false}
                fadingEdgeLength={96}
                overScrollMode="never"
                showsVerticalScrollIndicator={false}>

                { Array.from(questions).sort((a, b) => {
                        if (a === mainSelection) return -Infinity;
                        if (b === mainSelection) return +Infinity;
                        return 0;
                    }).map(k =>
                        <Question
                            switchPress={switchPress}
                            key={k}
                            stockState={stockState[k]}
                            style={{ marginBottom: Spacing.XL }}
                        />
                    )
                }
            </ScrollView>
        </Animated.View>
    )

}

ChangeStockModal.display = (fungibleNode, onDismiss) => (option, selectionTree) => {
    const nodeId = option.nodeId;

    _pushModal({
        style: {
            justifyContent: 'center',
            alignItems: 'center'
        },
        children: <ChangeStockModal
            onDismiss={onDismiss}
            style={{ maxHeight: dHeight - Spacing.NAVBAR * 2, width: dWidth - Spacing.MARGINS * 2 }}
            selectionTree={selectionTree}
            productTree={ProductNode.copy(fungibleNode)}
            mainSelection={nodeId}
        />
    })

}