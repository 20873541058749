import { useTheme } from '@themeProvider';
import { ScrollView, Dimensions, View, Text, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Spacing, Typography, Mixins } from '@styles';
import { useLanguage } from '@languageProvider';

import Constants from 'expo-constants';
import { Button } from '@atoms';
const API_PREFIX = Constants.manifest.extra.API_PREFIX;


const dWidth = Dimensions.get('window').width;

export const SettingsScreen = () => {
    const { translate } = useLanguage();
    const { useColor, useElevation } = useTheme();
    const [ backgroundColor, title ] = useColor(['background', 'title']);
    const dispatch = useDispatch();
    const elevation = useElevation(2);

    return (
        <View style={{ flex: 1, backgroundColor, padding: Spacing.MARGINS }}>
            <Text style={[Typography.H6, {
                textAlign: 'center',
                color: title,
            }]}>{translate('useTheseQRCodes')}</Text>
            <View style={[Mixins.HORIZONTAL, { flexGrow: 1, justifyContent: 'space-around', alignItems: 'center' }]}>
                <View style={[Mixins.HORIZONTAL]}>
                    <Text style={[Typography.H6, { color: title, marginRight: Spacing.M}]}>1</Text>
                    <Image source={{ uri: API_PREFIX + 'api/support/qrcode?key=%5E%23SC%5E303FFF0' }} style={{
                        borderWidth: 1,
                        width: dWidth / 6,
                        height: dWidth / 6,
                    }} />
                </View>

                <View style={[Mixins.HORIZONTAL]}>
                    <Text style={[Typography.H6, { color: title, marginRight: Spacing.M }]}>2</Text>
                    <Image source={{ uri: API_PREFIX + 'api/support/qrcode?key=%5E%23SC%5E6060116' }} style={{
                        borderWidth: 1,
                        width: dWidth / 6,
                        height: dWidth / 6,
                    }} />
                </View>

                <View style={[Mixins.HORIZONTAL]}>
                    <Text style={[Typography.H6, { color: title, marginRight: Spacing.M }]}>3</Text>
                    <Image source={{ uri: API_PREFIX + 'api/support/qrcode?key=%5E%23SC%5E2050800' }} style={{
                        borderWidth: 1,
                        width: dWidth / 6,
                        height: dWidth / 6,
                    }} />
                </View>
            </View>

            <Button color='accent' title={translate('refreshApp')} icon='refresh' onPress={window.location.reload.bind(window.location)}/>
        </View>
    );
}