const manualOrders = {
    cash: 'Efectivo',
    "credit-card": "Tarjeta",
    "wristband-rfid": "Pulsera",
    addToCart: 'Añadir al carrito',
    paymentMethod: 'Método de pago',
    history: 'Historial',
    settings: 'Ajustes',
    currentOrder: 'Pedido actual',
    logOut: 'Salir',
    pay: 'Pagar',
    saveAsADraft: 'Guardar como borrador',
    deleteOrder: 'Borrar pedido',
    order: 'Pedido',
    paymentConfirmation: 'Confirmación del pago',
    cancel: 'Cancelar',
    markAsPaid: 'Pagado',
    theTotalIs: 'El total es',
    cashPaymentConfirmation: 'Puedes usar el campo de abajo para calcular el cambio. Recuerda marcar el pedido como pagado cuando hayas terminado.',
    "wristband-rfidPaymentConfirmation": 'Dile al cliente que acerque su pulsera al sensor.',
    "credit-cardPaymentConfirmation": 'Por favor, continua con tu TPV y marca el pedido como pagado cuando hayas terminado.',
    "genericPaymentConfirmation": 'Por favor, cobra al cliente. Recuerda marcar el pedido como pagado cuando hayas terminado. ',
    change: 'Cambio',
    eg: 'Ex',
    paidWith: 'Pagado con',
    selectAPaymentMethod: 'Por favor, selecciona un método de pago',
    somethingWentWrong: 'Parece que ha habido algún error',
    markedAsPaid: '¡Pedido marcado como pagado!',
    "applyADiscount?": '¿Aplicar un descuento?',
    discount: 'Descuento',
    free: 'Gratis',
    draftOrders: 'Pedidos pendientes',
    loadedOrder: 'Pedido cargado',
    in_progress: 'Borrador',
    processing: 'Procesando',
    on_going: 'En curso',
    completed: 'Completado',
    succeeded: 'Recibido',
    invalid: 'Inválido',
    refunded: 'Devuelto',
    transferred: 'Transferido',
    collapse: 'Ocultar',
    selectYourBar: 'Selecciona tu barra',
    readCodes: 'Códigos validados',
    individualProducts: 'Productos individuales',
    continue: 'Continuar',
    useTheseQRCodes: 'Por favor, lee estos códigos para configurar el lector de QRs.',
    refreshApp: 'Actualizar aplicación',
    soldOut: 'Agotado',
    editStock: 'Editar stock',
    finishEditingStock: 'Finalizar edición',
    inStock: 'En stock',
    stockEdited: 'Stock actualizado',
    packs: 'Packs',
    selectOneOption: 'Por favor, selecciona una opción',
    edit: 'Editar',
    remove: 'Eliminar'
}

const grill = {
    grill: 'Parrilla',
    pending: 'Pendientes',
    preparing: 'Preparando',
    ready: 'Listo',
    served: 'Servido',
    moveTo: 'Mover a',
    close: 'Cerrar',
    deactivateSales: 'Desactivar ventas',
    activateSales: 'Activar ventas',
    salesAreActive: 'Las ventas están activas',
    salesAreDeactive: 'Las ventas están desactivadas',
    deactivateSalesExplanation: 'Se desactivarán las ventas. Los clientes no podrán realizar compras hasta que estén activas de nuevo.',
    activateSalesExplanation: 'Se activarán las ventas. Los clientes podrán realizar compras online.',
    deliverTo: 'Entregar en',
    printTicket: 'Imprimir ticket',
    markAsServed: 'Marcar como servido',
    orderServed: 'Pedido servido',
    printGrillTicket: 'Imprimir comanda',
    printGrillTicketExplanation: '¿Quieres imprimir una comanda para la cocina?',
    printCustomerTicket: 'Imprimir ticket',
    printCustomerTicketExplanation: '¿Quires imprimir un ticket para el cliente?',
    print: 'Imprimir',
    moveToPreparing: 'Mover a preparación',
    summary: 'Resumen',
    batchReady: 'Grupo listo',
    nextBatch: 'Siguiente grupo',
    pendingOrders: 'Pedidos recibidos',
    app: 'App',
    ticket: 'Ticket',
    noPreparation: 'Sin preparación',
    noPendingOrdersExplanation: "No hay pedidos nuevos para preparar"
}


const validationErrors = {
    invalid: "Código inválido",
    V102: "Código inválido",
    incorrect_place: "Este código no pertecene al local",
    invalid_status: "El pedido aun se está procesando",
    invalid_service_type: "Este pedido no es de preparación",
    incorrect_pickupbar: "Este pedido es de otra barra",
    already_served: "Este pedido ya se ha servido",
    already_verified: "Código ya utilizado anteriormente",
    wrong_preparation_group: "Esta barra no prepara este pedido",
    somethingOutOfStock: "No hay stock de algo en el pedido",
    salesAreNotAvailable: 'Las ventas están desactivadas',
    connectionError: 'Error de conexión',
    invalid_token: 'Código erróneo',
    already_used: 'Código ya utilizado anteriormente',
    thisMenuIsOutdated: 'El menú estaba desactualizado'

}

const dates = {
    "today": "Hoy",
    "yesterday": "Ayer",
    "tomorrow": "Mañana",
    "twoDaysAgo": "Hace dos días",
    "monday": "lunes",
    "tuesday": "martes",
    "wednesday": "miércoles",
    "thursday": "jueves",
    "friday": "viernes",
    "saturday": "sábado",
    "sunday": "domingo",
    "theDay": "El {{day}}",
    "thePastDay": "El {{day}} pasado",
    "theFutureDay": "El próximo {{day}}",
    "midString": "El {{weekday}} {{date}} de {{month}}",
    "january": "enero",
    "february": "febrero",
    "march": "marzo",
    "april": "abril",
    "may": "mayo",
    "june": "junio",
    "july": "julio",
    "august": "agosto",
    "september": "septiembre",
    "october": "octubre",
    "november": "noviembre",
    "december": "diciembre",
    "momentAgo": "hace un momento",
    "weekAbbreviation": "sem",
    "year": "año",
    "years": "años",
    "longDate": "{{date}} de {{month}} de {{fullYear}}",
    "sundayAbbreviation": "Dom",
    "mondayAbbreviation": "Lun",
    "tuesdayAbbreviation": "Mar",
    "wednesdayAbbreviation": "Mié",
    "thursdayAbbreviation": "Jue",
    "fridayAbbreviation": "Vie",
    "saturdayAbbreviation": "Sab",
    "mondayLetter": "L",
    "tuesdayLetter": "M",
    "wednesdayLetter": "X",
    "thursdayLetter": "J",
    "fridayLetter": "V",
    "saturdayLetter": "S",
    "sundayLetter": "D",
}


const promotions = {
    allVenues: 'Cualquier local',
    spending: 'Al gastar',
    usesLeft: 'Quedan {{uses}}',
    promotions: 'Promociones',
    promo: 'Promo',
    discountOff: '{{discount}} menos',
    couponAvailable: 'Cupón disponible',
    'haveACode?': '¿Tienes un código?',
    'haveADiscount?': '¿Tienes un descuento?',
    addDiscount: 'Añadir descuento',
    addItHere: 'Añádelo aquí',
    discountCode: 'Código descuento',
    send: 'Enviar',
    invalidCode: 'Código inválido',
    promotionAdded: 'Promoción añadida',
    discounts: 'Descuentos',
    subtotal: 'Subtotal'
}

const spanishTranslateJSON = { 
    ...manualOrders,
    ...grill,
    ...dates,
    ...validationErrors,
    ...promotions
};
export default spanishTranslateJSON;