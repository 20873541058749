import service from '@services';
import { Order, PickupBar, PreparationGroup, Promotion } from '@datamodel';

const PREFIX = '/pickupbars';

export const list = async () => {
    const response = await service.get(`${PREFIX}/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            pickupBars: PickupBar.parseArray(response.response)
        }
    }
    throw service.error(response);
}

export const get = async (pk=service.bar_id) => {
    const response = await service.get(`${PREFIX}/${pk}/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            pickupBar: PickupBar.parse(response.response)
        }
    }
    throw service.error(response);
}

export const getPreparationGroups = async (pk=service.bar_id) => {
    const response = await service.get(`${PREFIX}/${pk}/preparation_groups/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            preparationGroups: PreparationGroup.parseArray(response.response)
        }
    }
    throw service.error(response);
}

export const deactivateSales = async (pk=service.bar_id) => {
    const response = await service.post(`${PREFIX}/${pk}/deactive_menu_sales/`);
    if (response.status === 'ok') {
        return {
            // TODO: Parse pickup bar
            status: 'ok'
        }
    }
    throw service.error(response);
}


export const activateSales = async (pk=service.bar_id) => {
    const response = await service.post(`${PREFIX}/${pk}/active_menu_sales/`);
    if (response.status === 'ok') {
        return {
            // TODO: Parse pickup bar
            status: 'ok'
        }
    }
    throw service.error(response);
}

export const getGrillOrders = async (pk=service.bar_id) => {
    const response = await service.get(`${PREFIX}/${pk}/orders_grill/`);
    if (response.status === 'ok') {
        // console.log(response.response)
        return {
            status: 'ok',
            orders: Order.parseArray(response.response)
        }
    }
    throw service.error(response);
}

export const setOrderServingStatus = async (pk=service.bar_id, orderId, servingStatus, back) => {
    const response = await service.post(`${PREFIX}/${pk}/orders_grill/${orderId}/${back ? 'return_to' : 'mark_as'}_${servingStatus}/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            order: Order.parse(response.response)
        }
    }
    throw service.error(response);
}

export const updateProductDefinition = async(pk = service.bar_id, pdId, body) => {
    const response = await service.put(`${PREFIX}/${pk}/product_definitions/${pdId}/`, body);
    if ( response.status === 'ok' ) {
        return {
            status: 'ok'
        }
    }
    throw service.error(response);
}

export const getPromotions = async (pk = service.bar_id) => {
    const response = await service.get(`${PREFIX}/${pk}/promotion_codes/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            promotions: Promotion.parseArray(response.response)
        }
    }
    throw service.error(response);
}


export const subscribePromotion = async ({ place, code }) => {
    const response = await service.post(`${PREFIX}/${pk}/promotion_codes/`, { code, place });
    if (response.status === 'ok') {
        return {
            status: 'ok',
            promotion: Promotion.parse(response.response)
        }
    }
    throw service.error(response);
}