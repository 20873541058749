import Constants from 'expo-constants';

export const TRACKING_CONFIG = {
    path: '',
    hostname: '',
    pickupBar: ''
}

const REALM_ID = Constants.manifest.extra.TRACKING_REALM;
const TRACKING_PREFIX = Constants.manifest.extra.TRACKING_PREFIX;

const HOW = {
    TAP: 'click',
    SCROLL: 'scroll',
    RETRIEVE: 'retrieve',
    VISIT: 'visit',
    CANCEL: 'cancel',
    SEARCH: 'search',
    START: 'start',
    COMPLETE: 'complete',
    PRINT_TICKET: 'print_ticket'
}


const record = async (event_type, metadata) => {
    const data = {
        realm: REALM_ID,
        event_type: event_type,
        host_name: TRACKING_CONFIG.hostname,
        path: TRACKING_CONFIG.path,
        metadata: {
            pickupbar: TRACKING_CONFIG.pickupBar,
            ...metadata
        },
    };

    let sid = window.localStorage.getItem('sid');
    let uid = window.localStorage.getItem('uid');

    if (sid === 'undefined') {
        sid = undefined;
    }
    if (uid === 'undefined') {
        uid = undefined;
    }

    if (sid && uid) {
        data.sid = sid;
        data.uid = uid;
    }

    let url = new URL('/bigbro/events/', TRACKING_PREFIX);
    const res = await fetch(url.toString(), {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain;charset=utf-8'
        },
        body: JSON.stringify(data)
    });

    const json = await res.json();

    if (sid !== json.sid) {
        window.localStorage.setItem('sid', json.sid);
        window.localStorage.setItem('uid', json.uid);
    }
};

const tap = (what, metadata) => {
    record(HOW.TAP, { what, ...metadata });
}

const scroll = (what, metadata) => {
    record(HOW.SCROLL, { what, ...metadata });
}

const printTicket = (what, metadata) => {
    record(HOW.PRINT_TICKET, { what, ...metadata });
}

export class Tracker {
    constructor(screen, component = 'RootView', placeId) {
        this.screen = screen;
        this.component = component;
        this.placeId = placeId;
    }

    static from(tracker) {
        return new this(tracker.screen, tracker.component, tracker.placeId);
    }

    setComponent(component) {
        this.component = component;
        return this;
    }

    setParams(params) {
        Object.assign(this, params);
        return this;
    }

    scroll(what, metadata) {
        if (this.contentObjectId) {
            scroll(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
                content_object_id: this.contentObjectId,
                content_object_type: this.contentObjectType
            });
        } else {
            scroll(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
            });
        }
    }

    tap(what, metadata) {
        if (this.contentObjectId) {
            tap(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
                content_object_id: this.contentObjectId,
                content_object_type: this.contentObjectType
            });
        } else {
            tap(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
            });
        }
    }

    printTicket(what, metadata) {
        if (this.contentObjectId) {
            printTicket(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
                content_object_id: this.contentObjectId,
                content_object_type: this.contentObjectType
            });
        } else {
            printTicket(what, {
                ...metadata,
                screen: this.screen,
                component: this.component,
            });
        }
    }
}