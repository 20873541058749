import { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Image, Platform, Linking, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Spacing, Mixins, Typography} from '@styles';
import { TextInput, Button } from '@atoms';
import FestoLogo from '@assets/images/FestoLogo';
import { selectBarLogin, getPickupBars } from '@actions';
import { useTheme } from '@themeProvider';
import { useLanguage } from '@languageProvider';
import service from '@services';

const styles = StyleSheet.create({
    input: {
        alignSelf: 'stretch'
    },
})

export const LoginScreen = () => {
    const { translate } = useLanguage();
    const { useColor, isDark } = useTheme();
    const bars = useSelector(state => state.pickupBars) || {};
    const token = useSelector(state => state.authentication.token );
    const manualLogOut = useSelector(state => state.authentication.manualLogOut);
    const dispatch = useDispatch();    
    const [ backgroundColor, title, primary ] = useColor(['background', 'title', 'primary']);

    const loginPress = async () => {
        try {
            if (service.api_token) {
                await dispatch(getPickupBars());
            } else {
                throw ('No access token');
            }
        } catch (error) {
            service.createOAuth();
            // service.redirect('terminal_api/login/', { origin: 'same' });
        }
    }

    // useEffect(() => {
    //     loginPress();
    // }, []);
    
    const barLogIn = async (bId) => {
        service.bar_id = bId;
        const bar = bars[bId];
        dispatch(selectBarLogin({
            pickupBarId: bId,
            placeName: bar.place.name,
            pickupBarName: bar.name,
        }));
    }

    useEffect(() => {
        if (Object.keys(bars).length === 1 && service.api_token && !manualLogOut) {
            const bId = Object.keys(bars)[0];
            barLogIn(bId);
        }
    }, [bars]);

    return (
        <View style={{ backgroundColor, flex: 1}}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', }}>
                <View style={[Mixins.HORIZONTAL, { alignItems: 'center' }]}>
                    <Image source={require('@assets/images/FesteaPOS.webp')} style={{ width: 150, height: 150, marginBottom: Spacing.M }} />
                    <FestoLogo
                        style={{ width: 350, marginBottom: Spacing.MARGINS, height: 100 }}
                        logoStyle={isDark ? 'light' : 'dark'} />
                </View>
                {
                    Object.keys(bars).length > 1 ?
                    <View style={{ marginTop: Spacing.M }}>
                        <Text style={[Typography.H6, { color: title, textAlign: 'center' }]}>{translate('selectYourBar')}</Text>
                        { Object.keys(bars).map(bId => {
                            const name = bars[bId].name;
                            return <Button onPress={() => barLogIn(bId)} type="secondary" title={name} size="x-condensed" style={{ marginTop: Spacing.M}}/>
                        })}
                    </View>
                    :
                    <Button loadOnPress onPress={loginPress} title="Login" style={{ alignSelf: 'stretch', marginTop: Spacing.M }} />
                }
            </View>
        </View>
    );
}