import { ReadOrderModal } from '@modals';
import service, { Orders, Products, PickupBars, Validation, Trackings } from '@services';
import { _pushModal, _pushSnack } from '@lib';
import { _translate } from '@languageProvider';
import { Order } from '@datamodel';
import { objectGetAny } from '@utils/helpers';

export const DEVICE_LOGIN = 'DEVICE_LOGIN';
export const EXIT_BAR = 'EXIT_BAR';
export const SAVE_DRAFT = 'SAVE_DRAFT';
export const REMOVE_DRAFT = 'REMOVE_DRAFT';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const RECEIVE_MENUS = 'RECEIVE_MENUS';
export const RECEIVE_PICKUP_BARS = 'RECEIVE_PICKUP_BARS';
export const SELECT_BAR = 'SELECT_BAR';
export const SET_ACTIVE_SALES = 'SET_ACTIVE_SALES';
export const RECEIVE_GRILL_ORDERS = 'RECEIVE_GRILL_ORDERS';
export const SET_GRILL_ORDER_SERVING_STATUS = 'SET_GRILL_ORDER_SERVING_STATUS';
export const READ_QR = 'READ_QR';
export const CLEAR_READING_SESSION = 'CLEAR_READING_SESSION';
export const SET_READ_ORDER_MODAL_VISIBLE = 'SET_READ_ORDER_MODAL_VISIBLE';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_LAST_MENU = 'SET_LAST_MENU';
export const ADD_BATCH = 'ADD_BATCH';
export const WRITE_SETTINGS = 'WRITE_SETTINGS';
export const CLEAN_HISTORY_CURSOR = 'CLEAN_HISTORY_CURSOR';

const exitBar = ({
    type: EXIT_BAR
});

const signOut = ({
    type: SIGN_OUT
})

// export const authenticate = (token) => async (dispatch) => {
//     const response = await service.authenticate(token);
//     if (response.status === 'ok') {
//         const menu = (await Orders.getMenu()).menu;
//         await dispatch(deviceLogin({ token, ...response, menu }))

//     } else if (response.statusCode === 403) {
//         dispatch(hardLogOut);
//         alert('El código introducido no se corresponde con ningún local.')
//     } else {
//         alert('El código introducido no se corresponde con ningún local.')
//     }
// }

export const logOut = async (dispatch) => {
    service.barLogOut();
    dispatch(exitBar);
};

export const hardLogOut = async (dispatch) => {
    service.logOut();
    dispatch(signOut);
}

export const saveDraft = (serverCart, id, menu, totalPrice) => ({
    type: SAVE_DRAFT,
    createdAt: new Date(),
    serverCart,
    id,
    menu,
    totalPrice
})

export const removeDraft = (id) => ({
    id,
    type: REMOVE_DRAFT
})

export const receiveOrders = ({orders, next, previous}) => ({
    type: RECEIVE_ORDERS,
    orders,
    next,
    previous
})

export const cleanHistoryCursor = {
    type: CLEAN_HISTORY_CURSOR
}

export const receiveMenus = (menus) => ({
    type: RECEIVE_MENUS,
    menus
})

export const receivePickupBars = (pickupBars) => ({
    type: RECEIVE_PICKUP_BARS,
    pickupBars,
    token: service.api_token
})

export const selectBar = ({ pickupBarId, pickupBarName, placeName, preparationGroups }) => ({
    type: SELECT_BAR,
    pickupBarId,
    pickupBarName,
    placeName,
    preparationGroups
})

export const setActiveSales = ({ value, pickupBarId }) => ({
    type: SET_ACTIVE_SALES,
    value,
    pickupBarId
})

export const receiveGrillOrders = ({ orders, timestamp }) => ({
    type: RECEIVE_GRILL_ORDERS,
    orders,
    timestamp
})

export const setLastMenu = (lastMenu, lastPacks, lastPromotions) => ({
    type: SET_LAST_MENU,
    lastMenu,
    lastPacks,
    lastPromotions
})

export const markOrderAsPaid = (orderId, callback=()=>{}) => async dispatch => {
    const response = await Orders.markAsPaid(orderId);
    if (response.status === 'ok') {
        // dispatch(receiveOrders({
        //     [orderId]: response.order
        // }))
        _pushSnack({
            type: 'success',
            text: _translate('markedAsPaid'),
            icon: 'check-alt'
        })

        callback(response.order);
    } else if (response.statusCode == 403 || response.statusCode == 401) {
        dispatch(hardLogOut);
    }
}

export const getMenusIfNeeded = (menus) => async (dispatch, getState) => {
    const state = getState();
    const neededMenus = menus.filter(mId => !(mId in state.menus));
    try {
        const response = await Products.getCollection(neededMenus);
        if (Object.keys(response.products).length > 0) {
            dispatch(receiveMenus(response.products));
        }
    } catch (error) {
        if (error.statusCode === 403 || error.statusCode === 401) {
            dispatch(hardLogOut);
        }
    }
}

export const loadHistory = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const response = await Orders.history(state.history.next);
    
        if (response.status === 'ok') {
            const menus = new Set();
    
            Object.keys(response.orders).forEach(orderId => {
                const order = response.orders[orderId];
                Object.keys(order.purchases).map(pId => {
                    const purchase = order.purchases[pId]
                    if (!(purchase.menuNodeId in state.menus)) {
                        menus.add(purchase.menuNodeId);
                    }
                })
    
            })
    
            const menusResponse = await Products.getCollection([...menus]);
            if (menusResponse.status === 'ok') {
                if (Object.keys(menusResponse.products).length > 0){
                    dispatch(receiveMenus(menusResponse.products));
                }
                dispatch(receiveOrders({
                    orders: response.orders,
                    previous: response.previous,
                    next: response.next
                }));
            }
        } else if (response.statusCode == 403) {
            dispatch(hardLogOut);
        }
    } catch (error) {
        if (error.statusCode === 403 || error.statusCode == 401) {
            dispatch(hardLogOut);
        }
    }
}

export const getPickupBars = () => async (dispatch, getState) => {
    try {
        const response = await PickupBars.list();
        dispatch(receivePickupBars(response.pickupBars));

        const { pickupBarId } = getState().authentication;
        if ( pickupBarId && Object.keys(response.pickupBars).indexOf(pickupBarId) < 0 ) {
            dispatch(hardLogOut);
        }
    } catch (error) {
        if (error.statusCode === 403 || error.statusCode === 401 ) {
            throw ('createOAuth');
        }
    }
}

export const activateSales = (pickupBarId) => async (dispatch) => {
    try {
        dispatch(setActiveSales({
            value: true,
            pickupBarId
        }));
        const response = await PickupBars.activateSales(pickupBarId);
    } catch (error) {
        dispatch(setActiveSales({
            value: false,
            pickupBarId
        }));
        if (error.statusCode === 403 || error.statusCode == 401) {
            dispatch(hardLogOut);
        }
    }
}

export const deactivateSales = (pickupBarId) => async (dispatch) => {

    try {
        dispatch(setActiveSales({
            value: false,
            pickupBarId
        }));
        const response = await PickupBars.deactivateSales(pickupBarId);
    } catch (error) {
        dispatch(setActiveSales({
            value: true,
            pickupBarId
        }));
        if (error.statusCode === 403 || error.statusCode == 401) {
            dispatch(hardLogOut);
        }
    }
}

export const getGrillOrders = (pickupBarId) => async (dispatch) => {
    const timestamp = Date.now();
    try {
        const response = await PickupBars.getGrillOrders(pickupBarId);
        dispatch(receiveGrillOrders({
            orders: response.orders,
            timestamp
        }))

        const menusToFecth = new Set();

        Object.keys(response.orders).forEach(orderId => {
            const order = response.orders[orderId];
            const menuNodeIds = Order.getMenuIds(order);
            menuNodeIds.forEach(menusToFecth.add, menusToFecth);
        });

        dispatch(getMenusIfNeeded([...menusToFecth]));

    } catch (error) {
        console.error(error)
        if (error.statusCode === 403 || error.statusCode == 401) {
            dispatch(hardLogOut);
        }
    }
}

export const setGrillOrderServingStatus = (pickupBarId, config = {orderId: '', servingStatus: '', currentStatus: '', back: false}) => async (dispatch) => {
    const { orderId, servingStatus, currentStatus, back } = config;
    const timestamp = Date.now();
    try {
        dispatch({
            type: SET_GRILL_ORDER_SERVING_STATUS,
            status: servingStatus,
            currentStatus,
            orderId,
            timestamp
        })
        const response = await PickupBars.setOrderServingStatus(pickupBarId, orderId, servingStatus, back);
    } catch (error) {
        dispatch({
            type: SET_GRILL_ORDER_SERVING_STATUS,
            currentStatus: servingStatus,
            status: currentStatus,
            orderId,
            timestamp
        })

        _pushSnack({
            type: 'warning',
            icon: 'warning',
            text: _translate('somethingWentWrong')
        })

        if (error.statusCode === 403 || error.statusCode == 401) {
            dispatch(hardLogOut);
        }
    }
}

export const clearReadingSession = {
    type: CLEAR_READING_SESSION
}

export const readQR = ({purchases, order, purchaseGroup }) => ({
    type: READ_QR,
    purchases,
    order,
    purchaseGroup
})

export const setReadOrderModalVisible = (value=true) => ({
    type: SET_READ_ORDER_MODAL_VISIBLE,
    value
})

export const scanCode = (code) => async (dispatch, getState) => {
    try {
        const response = await Validation.validate(code);
        if (response.status === 'ok') {
            const { orderRecord } = response;
            const { order, purchases, purchaseGroup } = orderRecord;
            dispatch(readQR({
                purchases,
                order,
                purchaseGroup
            }));

        } else if (response.statusCode === 403) {
            dispatch(logOut);
        } else {
            throw response;
        }
    } catch (error) {
        switch (error?.statusCode) {
            case 403:
            case 401:
                dispatch(hardLogOut);
                break;
            case 422:
                _pushSnack({
                    type: 'warning',
                    icon: 'warning',
                    text: _translate(error.keycode),
                })
                break;
            default:
                _pushSnack({
                    type: 'warning',
                    icon: 'warning',
                    text: _translate('somethingWentWrong')
                })
                console.log(error);
        }
    }
};

export const selectBarLogin = ({
    pickupBarId,
    placeName,
    pickupBarName,
}) => async (dispatch, getState) => {
    try {
        const response = await PickupBars.getPreparationGroups(pickupBarId);
        dispatch(selectBar({
            pickupBarId,
            placeName,
            pickupBarName,
            preparationGroups: response.preparationGroups || {}
        }));
        
        Trackings.TRACKING_CONFIG.pickupBar = pickupBarId;

    } catch (error) {
        if (error.statusCode === 403 || error.statusCode == 401) {
            dispatch(hardLogOut);
        } else {
            _pushSnack({
                type: 'warning',
                icon: 'warning',
                text: _translate('somethingWentWrong')
            })
        }
    }
}

export const addGrillBatch = ({
    pickupBarId,
    batch
}) => ({
    type: ADD_BATCH,
    pickupBarId,
    batch
});

export const writeSettings = (settings) => ({
    type: WRITE_SETTINGS,
    settings
})