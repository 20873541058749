import { OrderRow, PurchaseInfo } from '@molecules';
import { cleanHistoryCursor, loadHistory } from '@redux/actions';
import { useTheme } from '@themeProvider';
import { useCallback, useState } from 'react';
import { ScrollView, Dimensions, View, Pressable, Text, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Spacing, Typography } from '@styles';
import { Order } from '@datamodel';
import { useFocusEffect } from '@react-navigation/native';
import { useModal } from '@lib';
import { useLanguage } from '@languageProvider';
import { objectGetAny } from '@utils/helpers';
import { printIframeAsync } from '@utils/printing';
import { Trackings } from '@services';
import { ActivityIndicator } from 'react-native-web';

const dWidth = Dimensions.get('window').width;

const screenTracker = new Trackings.Tracker('HistoryScreen');

export const HistoryScreen = () => {
    const [isFetching, setIsFetching] = useState(false);
    const { translate } = useLanguage();
    const { useColor, useElevation } = useTheme();
    const dispatch = useDispatch();
    const { pushActionSheet } = useModal();
    const {
        orders, previous, next
    } = useSelector(state => state.history) || {};
    const { menus, preparationGroups } = useSelector(state => ({ menus: state.menus, preparationGroups: state.preparationGroups }));
    const pickupBar = useSelector(state => state.pickupBars[state.authentication.pickupBarId]);
    const [ backgroundColor, title, primary ] = useColor(['background', 'title', 'primary']);
    const elevation = useElevation(2);
    const elevation12 = useElevation(12);

    const pressOrderRow = (order) => {
        const stackedOrder = Order.stackPurchases(order);
        const actions = [{
            displayText: translate('printTicket'),
            function: () => {
                try {
                    screenTracker.printTicket('ClientTicket', {
                        order: order.orderId
                    })
                    const iframe = document.querySelector('#printf');
                    const iwindow = iframe.contentWindow;
                    iwindow.document.write(
                        Order.asTicketHTML(order, preparationGroups, menus, { pickupBar })
                    )
                    iwindow.document.close();
                    printIframeAsync(iframe);
                } catch (error) {
                    console.log('PRINT ERROR:', error);
                }
                return true;
            }
        }];

        if (Order.isGrillPrintable(order, preparationGroups)) {
            actions.unshift({
                displayText: translate('printGrillTicket'),
                function: () => {
                    try {
                        screenTracker.printTicket('KitchenTicket', {
                            order: order.orderId
                        })
                        const iframe = document.querySelector('#printf');
                        const iwindow = iframe.contentWindow;
                        iwindow.document.write(
                            Order.asGrillTicketHTML(order, preparationGroups, menus)
                        )
                        iwindow.document.close();
                        printIframeAsync(iframe);
                    } catch (error) {
                        console.log('PRINT ERROR:', error);
                    }
                    return true;
                }
            })
        }
        
        pushActionSheet({
            actions,
            children: <View style={{ paddingHorizontal: Spacing.MARGINS, paddingVertical: Spacing.S }}>
                <Text style={[Typography.H6, { fontFamily: 'monospace', color: title, textAlign: 'center', marginBottom: Spacing.XS }]}>{Order.getShortCode(order).map(shortCode => `${shortCode}`).join(' ')}</Text>
                    {Object.keys(stackedOrder.purchases).map(selId => {
                        const { quantity, ...purchase } = stackedOrder.purchases[selId];
                        const menu = menus[purchase.menuNodeId];
                        if (!menu) return <></>
                        return <PurchaseInfo key={selId} showArea={false} {...{ quantity, purchase, menu }} />
                    })}
            </View>
        }, {
            style: {
                paddingHorizontal: dWidth * .3
            }
        })
    }

    useFocusEffect(useCallback(() => {
        dispatch(loadHistory());

        return () => {
            dispatch(cleanHistoryCursor);
        }
    }, []));

    const sortedOrders = Object.keys(orders).sort((o1, o2) => orders[o2].succeededAt - orders[o1].succeededAt);

    const fetchMoreOrders = async () => {
        if (next) {
            setIsFetching(true);
            await dispatch(loadHistory());
            setIsFetching(false);
        }
    }

    const renderItem = useCallback(({ item: orderId }) => (
        <Pressable onPress={() => pressOrderRow(orders[orderId])}>
            <OrderRow key={orderId} style={[elevation, { width: .5 * dWidth, marginBottom: Spacing.M }]} order={orders[orderId]} />
        </Pressable>
    ), [orders]);
    
    return (
        <>
            {
                sortedOrders.length ?
                <FlatList
                    keyExtractor={id => id}
                    onEndReached={fetchMoreOrders}
                    onEndReachedThreshold={3}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{ alignItems: 'center'}}
                    data={sortedOrders}
                    renderItem={renderItem}
                    ListFooterComponent={
                        isFetching ?
                            <View style={{ paddingVertical: Spacing.S, alignItems: 'center' }}>
                                <ActivityIndicator animating={isFetching} size="large" color={primary} style={{ padding: Spacing.XXS, borderRadius: 100, backgroundColor: elevation12 }} />
                            </View> : <></>
                    }
                    style={{ flex: 1, backgroundColor, padding: Spacing.MARGINS }}/>
                :
                <View style={{ flex: 1, backgroundColor, padding: Spacing.MARGINS }}>
                </View>
            }
        </>
    );
}