import { Model, Parser, Tree, Coupon } from '@datamodel';
import { objectMap, mapObjectValues, addClassMethods, objectFilter } from '@utils/helpers';

class ProductNodePriceTaxRate extends Model {

    static get model() {
        return {
            country: '',
            displayName: '',
            inclusive: true,
            percentage: '',
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, ProductNodePriceTaxRate.unserialize({ ...ProductNodePriceTaxRate.model, ...args }, { toClass: true }))
    }

}

class ProductNodePrice extends Model {

    static get model() {
        return {
            amount: 0,
            unit: '',
            remaining: null,
            salesStartedAt: new Date(), // Should be null?
            salesEndedAt: new Date(), // Should be null?
            outOfStock: false,
            soldOut: false,
            taxRate: ProductNodePriceTaxRate.model
        }
    }

    static get parseMap() {
        return {
            salesStartedAt: d => Parser.date(d),
            salesEndedAt: d => Parser.date(d),
            taxRate: rate => ProductNodePriceTaxRate.parse(rate)
        }
    }

    static get unserialMap() {
        return {
            taxRate: rate => ProductNodePriceTaxRate.unserialize(rate)
        }
    }

    static get toClassMap() {
        return {
            taxRate: rate => new ProductNodePriceTaxRate(rate)
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, ProductNodePrice.unserialize({ ...ProductNodePrice.model, ...args }, { toClass: true }))
    }

}

export class ProductNode extends Model {

    // The tree methods are added to ProductNode
    // after this class definition as it cannot
    // extend both classes.

    static get uuid() { return 'nodeId'; }

    static get model() {
        return {
            name: '',
            displayName: '',
            mainQuestion: null,
            description: null,
            order: 0,
            fungible: false,
            packOnly: false,
            minChildren: 0,
            maxChildren: 0,
            requiresAnotherProduct: false,
            isRequired: false,
            price: ProductNodePrice.model,
            children: {},
            photo: 'https://static.festea.party/web/festea-logo-blanco.svg', // TODO: Change this with a festea primary color or ticket image
        }
    }

    static get keymap() {
        return {
            id: 'nodeId',
            name: 'displayName',
            internal_name: 'name',
            order_within_definition: 'order',
            min_children_selection: 'minChildren',
            max_children_selection: 'maxChildren'
        }
    }

    static get parseMap() {
        return {
            price: price => ProductNodePrice.parse(price),
            children: children => objectMap(
                children.map(c => ProductNode.parse(c)),
                ProductNode.uuid
            ),
        }
    }

    static get unserialMap() {
        return {
            price: price => ProductNodePrice.unserialize(price),
            children: children => mapObjectValues(
                children,
                c => ProductNode.unserialize(c)
            )
        }
    }

    static get toClassMap() {
        return {
            price: price => new ProductNodePrice(price),
            children: children => mapObjectValues(
                children,
                c => new ProductNode(c)
            )
        }
    }

    static get parseCallback() {
        return parsed => {
            parsed.name = parsed.name || parsed.displayName;
            return parsed;
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, ProductNode.unserialize({ ...ProductNode.model, ...args }, { toClass: true }))
    }

    /* Class utilities */

    static _print(tree, depth = 0) {
        console.log((' ').repeat(depth * 2), `[${tree.nodeId}]`, tree.name, '@', tree.price.amount, tree.fungible ? '*' : ' ', ProductNode.isSoldOut(tree) ? 'X' : ' ');
        Object.keys(tree.children).forEach(id => {
            this._print(tree.children[id], depth + 1)
        })
    }

    static isSoldOut(tree) {
        return tree.displayAsOutOfStock || tree.price.outOfStock || tree.price.soldOut
    }

    static getOutOfStock(tree) {
        const outOfStock = [];
        this._tranverseTree(tree, (node) => {
            if (this.isSoldOut(node)) {
                outOfStock.push(node.nodeId);
            }
        });
        return outOfStock;
    }

    static calculateTreePrices(tree) {

        const prices = {};

        const func = (node) => {
            if (!(node.nodeId in prices)) {
                prices[node.nodeId] = node.price.amount;
            } else {
                prices[node.nodeId] += node.price.amount;
            }

            Object.keys(node.children).forEach(key => {
                const child = node.children[key];
                prices[child.nodeId] = prices[node.nodeId];

            });
        }

        this._tranverseTree(tree, func);
        return prices;
    }

    static calculateTreeFromPrices(tree) {
        //TODO: Review this function
        const fromPrices = {};

        const func = (node) => {
            const childrenPrices = Object.keys(node.children).map(key => fromPrices[key] || 0);

            if (node.minChildren === 0) {
                fromPrices[node.nodeId] = 0;
                return;
            }

            // TODO: Think this default better. Before it was node.price.amount
            const minPrice = childrenPrices.length ? Math.min(...childrenPrices) : node.price.amount;
            fromPrices[node.nodeId] = minPrice;
        }

        this._tranverseReverseTree(tree, func);

        return fromPrices;
    }

    static calculateMinMaxPrices(tree) {
        const prices = {};

        const func = (node) => {
            const childrenPrices = Object.keys(node.children).map(key => prices[key] || 0);
            const minPrice = childrenPrices.map(n => n.min).sort().slice(0, node.minChildren).reduce((a, b) => a + b, 0);
            const maxPrice = childrenPrices.map(n => n.max).sort((a, b) => b - a).slice(0, node.maxChildren).reduce((a, b) => a + b, 0);

            prices[node.nodeId] = {
                min: node.price.amount + minPrice,
                max: node.price.amount + maxPrice
            }
        }

        this._tranverseReverseTree(tree, func);

        return prices;
    }

    /**
     * Returns the required node if any in the tree.
     * Limitations: This only works when there's <=1 required item.
     * @param {ProductNode} tree Product tree
     * @returns {ProductNode?} Required item or null
     */
    static findRequired(tree) {
        let required;

        this._tranverseTree(tree, (node) => {
            if (required) return;
            if (node.isRequired) {
                required = node;
            }
        })

        return required;
    }

    static getRequiringProducts(tree) {
        const requiring = {};

        this._tranverseTree(tree, (node) => {
            if (node.requiresAnotherProduct) {
                requiring[node.nodeId] = true;
            }
        })

        return requiring;
    }

    static findSelectables(tree,
        { areaId, depth, result, parent, parentOrder, areaName, fungibleParent } = { depth: 0, result: {} }
    ) {
        // A ProductNode with minChildren === maxChildren === 0 is selectable only if is fungible
        if (tree.minChildren === 0 && tree.maxChildren === 0 && tree.fungible && depth === 1) {
            result[tree.nodeId] = {
                areaId: tree.nodeId,
                depth: depth + 1,
                parent,
                parentOrder,
                areaName: tree.name,
            }
        }

        // A ProductNode is selectable if minChildren >= 1 and it's not the root
        if (tree.minChildren >= 1 && depth >= 1) {
            result[tree.nodeId] = {
                areaId,
                depth,
                parent,
                parentOrder,
                fungibleParent,
                areaName
            }

        }

        const areas = Object.keys(tree.children);
        areas.forEach(nodeId => {
            this.findSelectables(
                tree.children[nodeId],
                {
                    areaId: areaId || nodeId,
                    areaName: areaName || tree.children[nodeId].name,
                    depth: depth + 1,
                    result,
                    parent: tree.nodeId,
                    parentOrder: tree.order,
                    fungibleParent: fungibleParent || (tree.fungible ? tree.nodeId : undefined),

                })

        })

        return result;

    }

    // Cut-out productNodes that have packOnly=true, so they
    // can be purchased independently
    static standaloneProducts = (tree) => {
        const children = {};

        for (const cId in tree.children) {
            const child = tree.children[cId];

            if (!child.packOnly) {
                children[cId] = this.standaloneProducts(child);
            }

        }

        return {
            ...tree,
            children
        }
    }

    static propagatePrices(tree) {

        // BOTTOM TO TOP
        this._tranverseReverseTree(tree, node => {
            const cKeys = Object.keys(node.children);

            if (!cKeys.length) {
                node.displayAsOutOfStock = ProductNode.isSoldOut(node);
                return;
            }

            node.displayAsOutOfStock = ProductNode.isSoldOut(node) ||
                (node.minChildren >= 1 && cKeys.every(cId => ProductNode.isSoldOut(node.children[cId])));

        });

        // TOP TO BOTTOM
        this._tranverseTree(tree, node => {
            if (ProductNode.isSoldOut(node)) {
                const cKeys = Object.keys(node.children);
                cKeys.forEach(cId => {
                    node.children[cId].displayAsOutOfStock = true;
                })
            }
        });
    }

    /** UI FORMATTING **/


    /**
     * Finds and formats all the fungible nodes in a sub-tree of the menu.
     * @param {ProductNode} tree A sub-tree of the menu
     * @returns ({ node: ProductNode, nodeId, options: [] })[]
     */
    static formatFungibles(tree) {
        const fungibles = [];
        const prices = this.calculateTreePrices(tree);
        const minMaxPrices = this.calculateMinMaxPrices(tree);

        const format = (node) => {

            if (node.fungible) {
                let options;

                if (node.mainQuestion) {
                    this._tranverseTree(node, u => {
                        if (u.nodeId === node.mainQuestion) {
                            options = Object.keys(u.children).map(id => ({
                                ...u.children[id],
                                _optional: u.minChildren <= 0,
                                uiPrice: prices[id],
                                fromFlag: minMaxPrices[id].min < minMaxPrices[id].max
                            })).sort((a, b) => a.order - b.order);
                        }
                    });
                }

                fungibles.push({
                    node: {
                        ...node,
                        uiPrice: prices[node.nodeId],
                        fromFlag: minMaxPrices[node.nodeId].min < minMaxPrices[node.nodeId].max,
                    },
                    options,
                    nodeId: node.nodeId
                })
            }
        };

        this._tranverseTree(tree, format);

        return fungibles.sort((a, b) => a.node.order - b.node.order);
    }

    /**
     * This function gets the areas to show in the UI from a menu.
     * 
     * @param {ProductNode} tree A ProductNode tree representing a menu
     * @returns Area({ areaId, name, fungibleProducts: [] })[]
     */
    static formatAreas(tree) {
        ProductNode.propagatePrices(tree)
        return Object.keys(tree.children).map(areaId => {
            const node = tree.children[areaId];
            const fungibleProducts = this.formatFungibles(node);
            return {
                areaId,
                name: node.name,
                fungibleProducts,
                order: node.order || 0
            }
        }).sort((a, b) => a.order - b.order);
    }

    /**
     * Gets the real main options of a fungible. Differs from those in formatFungibles
     * only when the fungible does not have a mainQuestion. In that case, the mainQuestion
     * is the fungible itself.
     * 
     * @param {ProductNode} fungibleNode The product node of a fungible product.
     * @returns ProductNode[]
     */
    static findOptions(fungibleNode) {
        const fungibles = this.formatFungibles({
            ...fungibleNode,
            mainQuestion: fungibleNode.mainQuestion || fungibleNode.nodeId
        });

        if (fungibles.length) {
            const options = fungibles[0].options;
            if ( options.every(o => Object.keys(o.children).length === 0)) {
                return options;
            }
        }

        return [];
    }

    // POS only
    static copy(productNode) {
        return JSON.parse(JSON.stringify(productNode));
    }
}

// ADD Tree properties to ProductNode
addClassMethods({
    fromClass: Tree,
    toClass: ProductNode
})

class ProductPackItem extends Model {

    static get uuid() { return 'itemId'; }

    static get model() {
        return {
            quantity: 0,
            order: 0,
            product: ProductNode.model,
            coupon: null
        }
    }

    static get keymap() {
        return {
            id: ProductPackItem.uuid,
            order_within_definition: 'order',
            product_definition: 'product',
        }
    }

    static get unserialMap() {
        return {
            product: p => ProductNode.unserialize(p),
            coupon: coupon => Coupon.unserialize(coupon)
        }
    }

    static get toClassMap() {
        return {
            product: p => new ProductNode(p),
            coupon: coupon => new Coupon(coupon)
        }
    }

    static get parseMap() {
        return {
            product: p => ProductNode.parse(p),
            coupon: coupon => Coupon.parse(coupon)
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, ProductPackItem.unserialize({ ...ProductPackItem.model, ...args }, { toClass: true }))
    }
}

export class ProductPack extends Model {

    static get uuid() { return 'packId'; }

    static get model() {
        return {
            items: {},
            name: '',
            photo: '',
            description: null,
        }
    }

    static get keymap() {
        return {
            id: ProductPack.uuid,
        }
    }


    static get parseMap() {
        return {
            items: items => objectMap(
                items.map(i => ProductPackItem.parse(i)),
                ProductPackItem.uuid
            ),
        }
    }


    static get unserialMap() {
        return {
            items: items => mapObjectValues(
                items,
                i => ProductPackItem.unserialize(i)
            ),
        }
    }

    static get toClassMap() {
        return {
            items: items => mapObjectValues(
                items,
                i => new ProductPackItem(i)
            ),
        }
    }


    constructor(args) {
        super(args);
        Object.assign(this, ProductPack.unserialize({ ...ProductPack.model, ...args }, { toClass: true }))
    }

    static getNumberOfProducts(pack) {
        return Object.keys(pack.items).reduce((prev, itemId) => {
            return prev + pack.items[itemId].quantity;
        }, 0)
    }

    static getProductIds(pack, { repetitions, sorted } = {}) {
        const itemIds = Object.keys(pack.items);

        if (sorted) {
            itemIds.sort((id1, id2) => pack.items[id1].order - pack.items[id2].order)
        }

        if (repetitions) {
            return itemIds.map(itemId => {
                const item = pack.items[itemId];
                return Array(item.quantity).fill({ itemId, productId: pack.items[itemId].product.nodeId });
            }).flat();
        }
        return itemIds.map(itemId => ({ itemId, productId: pack.items[itemId].product.nodeId }));
    }

    static getPackItemByProductId(pack, productId) {
        let packItemId = Object.keys(pack.items).find(id => {
            const packItem = pack.items[id];
            return packItem.product.nodeId === productId;
        })
        return pack.items[packItemId];
    }

    static getModalAreas(pack) {
        const itemProductIds = this.getProductIds(pack, { repetitions: true, sorted: true });

        return itemProductIds.map(({ itemId, productId }) => ProductNode.formatAreas({
            ...ProductNode.model, // TODO: This should be handled anywhere else?
            children: {
                [productId]: pack.items[itemId].product
            }
        })).flat();
    }

    static collectionAddInformation(packs, menu) {

        const productIds = Object.keys(packs).map(packId =>
            this.getProductIds(packs[packId])
        ).flat();

        const productNodes = ProductNode.getTreeNodes(menu, productIds.map(pIds => pIds.productId));

        for (const packId in packs) {
            const items = packs[packId].items;
            for (const itemId in items) {
                const nodeId = items[itemId].product.nodeId;
                if (nodeId in productNodes) {
                    items[itemId].product = productNodes[nodeId];
                } else {
                    packs[packId]._notAvailable = true;
                }
            }
        }

        return objectFilter(packs, p => !p._notAvailable);
    }

}