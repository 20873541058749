import { Model, Parser, CartSelection, ProductNode, Discount } from '@datamodel';
import { objectMap, mapObjectValues, objectFilter, parsePrice, chunk, objectGetAny } from '@utils/helpers';
import { _translate } from '@languageProvider';
import { QREncodeURI } from '@services';


export class Purchase extends Model {

    static get IN_PROGRESS() { return 'in_progress'; }
    static get PROCESSING() { return 'processing'; }
    static get INVALID() { return 'invalid'; }
    static get REDEEMABLE() { return 'redeemable'; }
    static get USED() { return 'used'; }

    static get REFUNDED() { return 'refunded'; }
    static get TRANSFERRED() { return 'transferred'; }

    static get uuid() { return 'purchaseId'; }

    static get model() {
        return {
            amount: 0,
            selection: CartSelection.model,
            discounts: {},
            amountOff: 0,
            total: 0,
            // _selectionId: '',
            status: Purchase.IN_PROGRESS,
            type: 'drink',
            invalidReason: null,
            token: '',
            menuNodeId: '',
            usedAt: null,
        }
    }

    static get keymap() {
        return {
            id: 'purchaseId',
            product_selection: 'selection',
            customer_total: 'total',
            menu_root: 'menuNodeId'
        }
    }

    static get parseMap() {
        return {
            discounts: discounts => objectMap(
                discounts.map(d => Discount.parse(d)),
                Discount.uuid
            ),
            selection: sel => CartSelection.parse(sel),
            usedAt: d => Parser.date(d)
        }
    }

    static get unserialMap() {
        return {
            discounts: discounts => mapObjectValues(
                discounts,
                d => Discount.unserialize(d)
            ),
            selection: sel => CartSelection.unserialize(sel),
        }
    }

    static get toClassMap() {
        return {
            discounts: discounts => mapObjectValues(
                discounts,
                d => new Discount(d)
            ),
            selection: sel => new CartSelection(sel),
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, Purchase.unserialize({ ...Purchase.model, ...args }, { toClass: true }))
    }

    static hasRedeemableType(purchase, type) {
        return purchase.status === 'redeemable' && purchase.type === type;
    }


    static addSelectionId(purchase) {
        purchase._selectionId = CartSelection.getId(purchase.selection);
        return purchase;
    }

    static stackPurchases(purchases) {

        return Object.keys(purchases).reduce((prev, nextKey) => {

            const next = Purchase.addSelectionId(purchases[nextKey]);
            const selId = next._selectionId;

            if (selId in prev) {
                prev[selId].quantity++;
                prev[selId].tokens.push(next.token);
            } else {
                prev[selId] = { ...next, quantity: 1, tokens: [next.token] };
            }

            return prev;
        }, {});
    }

    static getColor(purchase) {
        switch (purchase.status) {
            case Purchase.REDEEMABLE:
                return 'primary';
            case Purchase.INVALID:
                if (purchase.invalidReason === Purchase.REFUNDED)
                    return 'warning';
                return 'label';
            case Purchase.USED:
                return 'success';
            default:
                return 'label';
        }
    }

    static getSelectionLeafs(purchase) {
        return CartSelection.getLeafs(purchase.selection);
    }

    static addMainSelection(purchase, fungibleNode) {
        CartSelection.addMainSelection(purchase.selection, fungibleNode);
        return purchase;
    }

    static getNodeId(purchase) {
        return purchase.selection.item.nodeId;
    }

    // Gets a new server representation of the purchase selection.
    // It's intended to be used to generate a cart from a past order,
    // that's why ignoreId is true by default
    static getServerSelections(purchase, { ignoreId } = { ignoreId: true }) {
        return CartSelection.toServer(purchase.selection, { ignoreId });
    }

    static isRepeatable(purchase, menu) {
        return purchase.menuNodeId === menu.nodeId && CartSelection.hasStock(purchase.selection, menu);
    }

    static formatInfo(purchase, menu, opts) {
        if (!menu || JSON.stringify(menu) === '{}') {
            return { title: '', info: []};
        }
        const node = CartSelection.getFungibleNode(purchase.selection, menu);
        this.addMainSelection(purchase, node);
        return CartSelection.getSelectionInfo(purchase.selection, node, opts);
    }

    static collectionGroupByFirstId(purchases) {
        const stacked = {};
        Object.keys(purchases).forEach(pId => {
            const firstId = purchases[pId].selection.item.nodeId;
            if (firstId in stacked) {
                stacked[firstId][pId] = purchases[pId]
            } else {
                stacked[firstId] = { [pId]: purchases[pId] }
            }
        })
        return stacked;
    }
    
    static collectionHTMLInfo(purchases, menus, opts) {
        const stacked = Purchase.stackPurchases(purchases);
        return objectMap(Object.keys(stacked).map(purchaseId => {
            const { quantity, ...purchase } = stacked[purchaseId];
            const { title, info } = Purchase.formatInfo(purchase, menus[purchase.menuNodeId], opts);
            return ({
                purchaseId,
                info,
                title,
                purchase,
                quantity,
                tokens: purchase.tokens
            })
        }), 'purchaseId');
    }
}

export class PurchaseGroup extends Model {

    static get uuid() { return 'purchaseGroupId'; }

    static get model() {
        return {
            preparationGroupId: '',
            shortCode: '',
            purchases: {},
            token: null,
            servingStatus: null,
            preparingAt: null,
            readyAt: null,
            servedAt: null,
            note: null,
            purchaseTokens: []
        }
    }

    static get keymap() {
        return {
            id: 'purchaseGroupId',
            preparation_group: 'preparationGroupId',
        }
    }

    static get parseMap() {
        return {
            purchases: purchases => Purchase.parseArray(purchases)
        }
    }

    static get unserialMap() {
        return {
            purchases: purchases => mapObjectValues(
                purchases,
                p => Purchase.unserialize(p)
            ),
        }
    }

    static get toClassMap() {
        return {
            purchases: purchases => mapObjectValues(
                purchases,
                p => new Purchase(p)
            ),
        }
    }

    static asTicketHTML(purchaseGroup, prepG, menus, config = { singlePurchases: false }) {
        // const stackedPurchases = Purchase.stackPurchases(purchaseGroup.purchases);

        // const {total, amount} = Object.keys(stackedPurchases).reduce(({total, amount}, pId) => {
            
        //     return {
        //         total: total + stackedPurchases[pId].total * stackedPurchases[pId].quantity,
        //         amount: amount + stackedPurchases[pId].amount * stackedPurchases[pId].quantity,
        //     }
        // }, {
        //     total: 0,
        //     amount: 0
        // });

        const purchasesInfo = Purchase.collectionHTMLInfo(purchaseGroup.purchases, menus, { useDisplayName: true });

        const purchaseInfoStrings = Object.keys(purchasesInfo).map(pId => {
            const { quantity, info, title } = purchasesInfo[pId];
            const [ fungible, ...rest ] = info;
            return (`
                <tr>
                    <td style="font-size: 1.1rem;">${quantity}</td>
                    <td style="text-align: left;" ><span style="font-weight: bold; font-size: 1.1rem;">${title}${fungible ? ' · ' + fungible : ''}</span></td>
                </tr>
                ${ rest.length > 0 ? `<tr><td></td><td style="font-weight: normal; text-align: left; font-size: 1.1rem"><span>${rest.join(' · ')}</span></td></tr>` : ''}
            `)
        });

        return `
            ${ purchaseGroup.shortCode ? `<p id="title"><span>${purchaseGroup.shortCode}</span></p>` : ''}
            ${ prepG.name ? `<p style="font-size: 1.3rem; text-align: center; font-weight: bold">${prepG.name}</p>` : ''}
            ${ prepG.description ? `<p>${prepG.description}</p>` : ''}
            ${ prepG.note ? `<p>${prepG.note}</p>` : ''}

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th style="width: 99%"></th>
                    </tr>
                </thead>
                <tbody>
                    ${purchaseInfoStrings.join('\n')}
                </tbody>
            </table>

            <hr>
                
            <div style="text-align: center">
            ${ config.singlePurchases ?
                Object.keys(purchasesInfo).map(pId => {
                    const { info, title, tokens } = purchasesInfo[pId];
                    const [fungible, ...rest] = info;

                    const titleSpan = `<p style="font-weight: bold; font-size: 1.3rem;">${title}${fungible ? ' · ' + fungible : ''}</p>`;
                    const infoSpan = `<p>${rest.join(' · ')}</p>`;
                    return tokens.map(t => titleSpan + infoSpan + `<img src="${QREncodeURI([t])}" style="max-width: 100%">`).join('<br><hr><br>');
                })
                : `<img src="${QREncodeURI([purchaseGroup.token])}" style="max-width: 100%"><br><hr><br>`
            }
            </div>
        `
    }

    static asGrillTicketHTML(purchaseGroup, menus) {
        const purchasesInfo = Purchase.collectionHTMLInfo(purchaseGroup.purchases, menus);
        const purchaseInfoStrings = Object.keys(purchasesInfo).map(pId => {
            const { quantity, info, title, purchase } = purchasesInfo[pId];
            const [fungible, ...rest] = info;

            return (`
                <p style="font-size: 1rem">${quantity} x <span style="font-weight: bold;">${title}${fungible ? ' · ' + fungible : ''}</span>
                <br><span style="font-weight: normal; text-align: left; font-size: 1rem">${rest.join(' · ')}</span>
                </p>
            `)
        });

        if (Object.keys(purchaseGroup.purchases).length <= 0) {
            return '';
        }

        return `
            <div class="preparation-group">
                ${ purchaseGroup.shortCode ? `<p class="title-order" style="font-size: 2rem;"><span>${purchaseGroup.shortCode}</span></p>` : ''}
                ${purchaseInfoStrings.join('\n') }
                ${ purchaseGroup.shortCode ? `<p class="title-order" style="font-size: 2rem;"><span>${purchaseGroup.shortCode}</span></p>` : ''}
            </div>
        `
    }

}


export class Order extends Model {

    static get IN_PROGRESS() { return 'in_progress'; }
    static get PROCESSING() { return 'processing'; }
    static get ON_GOING() { return 'on_going'; }
    static get COMPLETED() { return 'completed'; }
    static get SUCCEEDED() { return 'succeeded'; }
    static get INVALID() { return 'invalid'; }

    static get REFUNDED() { return 'refunded'; }
    static get TRANSFERRED() { return 'transferred'; }

    static get SERVING_PENDING() { return 'pending'; }
    static get SERVING_PREPARING() { return 'preparing'; }
    static get SERVING_READY() { return 'ready'; }
    static get SERVING_SERVED() { return 'served'; }

    static get uuid() { return 'orderId'; }

    static get model() {
        return {
            userId: '',
            eventId: '',
            status: '',
            amount: 0,
            purchases: {},
            discounts: {},
            total: 0,
            festoFee: 0,
            reasonInvalid: null,
            succeededAt: new Date(),
            containsTicket: false,
            servingStatus: '',
            shortCode: null,
            email: '',
            serviceType: null,
            deliveryAddress: '',
            note: null,
            purchaseGroups: {}
        }
    }

    static get keymap() {
        return {
            user: 'userId',
            event: 'eventId',
            id: 'orderId',
            customer_total: 'total',
        }
    }

    static get parseMap() {
        return {
            // Will group purchases in parseCallback
            purchases: purchases => objectMap(
                purchases.map(p => Purchase.parse(p)),
                Purchase.uuid),
            discounts: discounts => objectMap(
                discounts.map(d => Discount.parse(d)),
                Discount.uuid
            ),
            succeededAt: d => Parser.date(d),
            purchaseGroups: purchaseGroups => PurchaseGroup.parseArray(purchaseGroups)
        }
    }

    static get parseCallback() {
        return (order) => {

            if (Object.keys(order.purchases).some(pkey => order.purchases[pkey].type === 'ticket')) {
                order.containsTicket = true;
            }

            let allStatus = Object.keys(order.purchases).map(pkey => ({
                status: order.purchases[pkey].status,
                invalidReason: order.purchases[pkey].invalidReason
            }));

            // If some purchase is in_progress the order is still in_progress
            if (allStatus.some(s => s.status === Purchase.IN_PROGRESS)) {
                order.status = Order.IN_PROGRESS;
                return order;
            }

            // If some purchase is processing the order is still processing
            if (allStatus.some(s => s.status === Purchase.PROCESSING)) {
                order.status = Order.PROCESSING;
                return order;
            }

            // If every purchase is invalid
            if (allStatus.every(s => s.status === Purchase.INVALID)) {
                order.status = Order.INVALID;
                // If every status is refunded, reason is refunded
                if (allStatus.every(s => s.reasonInvalid === Purchase.REFUNDED)) {
                    order.reasonInvalid = Order.REFUNDED;
                } else if (allStatus.every(s => s.reasonInvalid === Purchase.TRANSFERRED)) {
                    order.reasonInvalid = Order.TRANSFERRED;
                }
                return order;
            }

            // Else, filter out invalid statuses to find out order status
            allStatus = allStatus.filter(s => s.status !== Purchase.INVALID);

            if (allStatus.every(s => s.status === Purchase.REDEEMABLE)) {
                order.status = Order.SUCCEEDED;
            } else if (allStatus.every(s => s.status === Purchase.USED)) {
                order.status = Order.COMPLETED;
            } else {
                order.status = Order.ON_GOING;
            }

            return order;
        }
    }

    static stackPurchases(order) {
        // Group purchases by _selectionId
        const newOrder = { ...order };
        newOrder.purchases = Purchase.stackPurchases(newOrder.purchases);
        return newOrder;
    }

    static getDiscountedTotal(order) {
        // TODO: Change when server works
        // Sum purchases totals
        const purchasesTotal = Object.keys(order.purchases).reduce((total, pId) => {
            return total + order.purchases[pId].total;
        }, 0);
        
        // Apply discounts 
        return Discount.applyCollection(
            Object.keys(order.discounts).map(dId => order.discounts[dId]),
            purchasesTotal
        )

    }

    static get unserialMap() {
        return {
            purchases: purchases => mapObjectValues(
                purchases,
                p => Purchase.unserialize(p)
            ),
            discounts: discounts => mapObjectValues(
                discounts,
                d => Discount.unserialize(d)
            ),
            purchaseGroups: purchaseGroups => mapObjectValues(
                purchaseGroups,
                d => PurchaseGroup.unserialize(d)
            ),
        }
    }

    static get toClassMap() {
        return {
            purchases: purchases => mapObjectValues(
                purchases,
                p => new Purchase(p)
            ),
            discounts: discounts => mapObjectValues(
                discounts,
                d => new Discount(d)
            ),
            purchaseGroups: purchaseGroups => mapObjectValues(
                purchaseGroups,
                d => new PurchaseGroup(d)
            ),
        }
    }

    constructor(args) {
        super(args);
        Object.assign(this, Order.unserialize({ ...Order.model, ...args }, { toClass: true }))
    }

    static hasRedeemable(order) {

        return Object.keys(order.purchases).reduce((result, nextKey) => {
            const purchase = order.purchases[nextKey];

            if (Purchase.hasRedeemableType(purchase, 'ticket')) {
                result.hasTickets = true;
            }
            else if (Purchase.hasRedeemableType(purchase, 'drink')) {
                result.hasDrinks = true;
            }
            return result;

        }, { hasTickets: false, hasDrinks: false });

    }

    static getRedeemableTokens(order, type) {

        return Object.keys(order.purchases).flatMap(pkey => {
            const purchase = order.purchases[pkey];
            if (purchase.status === 'redeemable' && purchase.type === type) {
                return [purchase.token];
            }
            return [];
        });

    }

    static getColor(order) {
        switch (order.status) {
            case Order.SUCCEEDED:
                return 'primary';
            case Order.ON_GOING:
                return 'Dodger Blue';
            case Order.INVALID:
                if (order.invalidReason === Order.REFUNDED)
                    return 'warning';
                return 'label';
            case Order.COMPLETED:
                return 'success';
            default:
                return 'label';
        }
    }

    static getMenuIds(order) {
        const menus = new Set();
        Object.keys(order.purchases).forEach(pId => {
            menus.add(order.purchases[pId].menuNodeId);
        })
        return [...menus];
    }

    static collectionFilterByEvent(orderCollection, eventId) {
        return objectFilter(orderCollection, o => o.eventId === eventId);
    }

    static collectionSplitByRedeemable(orderCollection) {
        const orders = { redeemables: {}, unredeemables: {}, completed: {} }

        for (const orderId in orderCollection) {
            const o = orderCollection[orderId];
            if (o.status === Order.ON_GOING || o.status === Order.SUCCEEDED) {
                orders.redeemables[orderId] = o;
            } else if (o.status === Order.COMPLETED) {
                orders.completed[orderId] = o;
            } else {
                orders.unredeemables[orderId] = o;
            }
        }

        return orders;
    }

    static collectionSplitByType(orderCollection) {
        // TODO: Check wherever we use this cause
        // it might not be necessary
        const orders = { tickets: {}, products: {} }

        for (const orderId in orderCollection) {

            const { hasTickets } = this.hasRedeemable(orderCollection[orderId]);

            if (hasTickets) {
                orders.tickets[orderId] = orderCollection[orderId];
            }
        }

        return orders;
    }

    static addMainSelections(order, menu) {
        const fungibleNodes = ProductNode.getTreeNodes(menu, Object.keys(order.purchases).map(pId => Purchase.getNodeId(order.purchases[pId])))
        Object.keys(order.purchases).forEach(pId => {
            const purchase = order.purchases[pId];
            Purchase.addMainSelection(purchase, fungibleNodes[Purchase.getNodeId(purchase)])
        })
        return order;
    }

    static getServerSelections(order) {
        return Object.keys(order.purchases).map(pId => {
            return Purchase.getServerSelections(order.purchases[pId]);
        });
    }

    static isRepeatable(order, menu) {
        return Object.keys(order.purchases).reduce((status, pId) => {
            return status && Purchase.isRepeatable(order.purchases[pId], menu);
        }, true);
    }

    static moveServingStatus(order, delta) {
        const statuses = [
            Order.SERVING_PENDING,
            Order.SERVING_PREPARING,
            Order.SERVING_READY,
            Order.SERVING_SERVED
        ]
        const current = statuses.indexOf(order.servingStatus);
        const newIndex = Math.min(Math.max(0, current + delta), statuses.length - 1);
        return statuses[newIndex];
    }

    static nextServingStatus(order) {
        return this.moveServingStatus(order, 1);
    }

    static getServingColor(order) {
        switch (order.servingStatus) {
            case Order.SERVING_PENDING:
                return 'warning';
            case Order.SERVING_PREPARING:
                return 'Dodger Blue';
            case Order.SERVING_READY:
                return 'success';
            case Order.SERVING_SERVED:
                return 'primary';
            default:
                return 'label';
        }
    }


    static stackByServingStatus(orderCollection) {
        const orders = {
            [Order.SERVING_PENDING]: {},
            [Order.SERVING_PREPARING]: {},
            [Order.SERVING_READY]: {}
        }

        
        for (const orderId in orderCollection) {
            const o = orderCollection[orderId];
            orders[o.servingStatus][orderId] = o;
        }        

        return orders;
    }

    /**
     * Returns an array of orders split by preparation
     * group ids, each one with the purchases that involves
     * the preparation group. All other properties are left
     * unnafected.
     * @param {Order} order 
     */
    static splitByPreparationGroup(order) {
        return Object.keys(order.purchaseGroups).map(purchaseGroupId => {
            return ({
                ...order,
                purchases: order.purchaseGroups[purchaseGroupId].purchases,
                purchaseGroups: {
                    [purchaseGroupId]: order.purchaseGroups[purchaseGroupId]
                }
            })
        })
    }

    static getShortCode(order) {
        return Object.keys(order.purchaseGroups).filter(pgId => {
            const pg = order.purchaseGroups[pgId];
            return Object.keys(pg.purchases).length > 0;
        }).map(pgId => {
            return order.purchaseGroups[pgId]?.shortCode;
        });
    }

    static collectionStackPurchases(orders) {
        let purchases = {};
        for (const orderId in orders) {
            const order = orders[orderId];
            purchases = {
                ...purchases,
                ...order.purchases
            }
        };
        return Purchase.stackPurchases(purchases);
    }

    static isGrillPrintable (order, preparationGroups) {

        return Object.keys(order.purchaseGroups).some(purchaseGroupId => {
            const purchaseGroup = order.purchaseGroups[purchaseGroupId];
            const preparationGroup = preparationGroups[purchaseGroup.preparationGroupId];

            if (!preparationGroup || !preparationGroup.hasServingStatus) return false;
            
            return true;
        })
    }

    static asTicketHTML(order, preparationGroups, menus, config) {
        const purchaseGroupsHTML = Object.keys(order.purchaseGroups).map(purchaseGroupId => {
            const purchaseGroup = order.purchaseGroups[purchaseGroupId];
            const preparationGroup = preparationGroups[purchaseGroup.preparationGroupId];
            if ( !preparationGroup ) return '';

            let singlePurchases;
            switch (config.pickupBar.printQRsSeparately) {
                case 'always':
                    singlePurchases=true;
                    break;
                case 'never':
                    singlePurchases = false;
                    break;
                case 'optional':
                default:
                    singlePurchases = (preparationGroup.separateQRCodes == 'always' || preparationGroup.separateQRCodes == 'optional');
            }
            
            return PurchaseGroup.asTicketHTML(purchaseGroup,
                preparationGroup,
                menus,
                {...config, singlePurchases});
        });

        const { total, amount } = order;

        return `
        <style>
                html {
                    font-size: 12px;
                }
                hr {
                    border: 1px dashed black;
                }
                #title {
                    margin-top: 2rem;
                    font-size: 2rem;
                    text-align: center;
                }
                #title>span {
                    font-weight: bold;
                }
                #ticket {
                    background-color: white;
                    height: 100%;
                    width: 44mm;
                    top: 0;
                    left: 0;
                    margin: 0;
                    display: block;
                    padding: 2mm;
                    font-family: monospace;
                }
                td {
                    vertical-align: text-top;
                }
                td:first-child {
                    font-weight: bold;
                }
                td:last-child {
                    text-align: right;
                    font-weight: bold;
                }
                .total-row td {
                    border-top: 1px solid black;
                    padding-top: .5rem;
                }

                .title-order {
                    font-size: 1.25rem;
                    text-align: center;
                }
                .title-order > span {
                    font-weight: bold;
                }
            </style>
            <div id="ticket">
                ${ purchaseGroupsHTML.join('\n') }
                <table>
                <thead>
                    <tr>
                        <th style="width: 99%"></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    ${total - amount < 0 ? `
                    <tr class="total-row">
                        <td style="font-weight: normal;">${_translate('subtotal')}</td>
                        <td style="font-weight: normal;">${parsePrice(amount, '€')}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: normal;">${_translate('discounts')}</td>
                        <td style="font-weight: normal;">${parsePrice(total - amount, '€')}</td>
                    </tr>` : ''}
                    <tr class="total-row">
                        <td>Total</td>
                        <td>${parsePrice(total, '€')}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <br><br>.
        `
    }

    static asGrillTicketHTML(order, preparationGroups, menus) {
        const purchaseGroupsHTML = Object.keys(order.purchaseGroups).map(purchaseGroupId => {
            const purchaseGroup = order.purchaseGroups[purchaseGroupId];
            const preparationGroup = preparationGroups[purchaseGroup.preparationGroupId];
            
            if (!preparationGroup || !preparationGroup.hasServingStatus) return '';

            return PurchaseGroup.asGrillTicketHTML(purchaseGroup, menus);
        })
        
        return `
            <style>
                .title-order {
                    font-size: 1.25rem;
                    text-align: center;
                }
                .title-order > span {
                    font-weight: bold;
                }
                #ticket {
                    background-color: white;
                    height: 100%;
                    width: 44mm;
                    position: fixed;
                    top: 0;
                    left: 0;
                    margin: 0;
                    display: block;
                    padding: 2mm;
                    font-family: monospace;
                }
                .preparation-group {
                    border-bottom: 1px dashed black;
                }
                .tag {
                    font-size: 1.25rem;
                    padding: .5rem 1rem;
                    border: 2px solid black;
                    border-radius: 4px;
                    margin: auto;
                    text-align: center;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            </style>
            <div id="ticket">
                ${!order.email ? `<p class="tag">${_translate('ticket')}</p>` : ''}
                ${order.deliveryAddress ? `<p>${_translate('deliverTo')}: <span style="font-size: 1.2em; font-weight: bold">${order.deliveryAddress}</span></p>` : ''}
                ${order.note ? `<p>${order.note}</p>` : ''}
                ${ purchaseGroupsHTML.join('\n') }
            </div>
            <br><br>.
        `
    }
}