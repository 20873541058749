import service from '@services';
import { ProductNode, ProductPack, Order } from '@datamodel';

const PREFIX = '/orders';

export const list = async () => {
    const response = await service.get(`/pickupbars/${service.bar_id}${PREFIX}/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            orders: Order.parseArray(response.response)
        }
    }
    throw service.error(response);
}

/**
 * Paginated endpoint for History
 * @param {*} next The next endpoint to fetch from. If null will fetch /pickupbars/${service.bar_id}/history/.
 * @returns 
 */
export const history = async (next) => {
    const response = await service.get(next ?? `/pickupbars/${service.bar_id}/orders_history/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            orders: Order.parseArray(response.response.results),
            next: response.response.next,
            previous: response.response.previous
        }
    }
    throw service.error(response);
}

export const get = async (pk) => {
    const response = await service.get(`/pickupbars/${service.bar_id}${PREFIX}/${pk}/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            order: Order.parse(response.response)
        }
    }
    throw service.error(response);
}

export const getMenu = async () => {
    const response = await service.get(`/pickupbars/${service.bar_id}${PREFIX}/menu/`);
    if (response.status === 'ok') {
        const menu = ProductNode.parse(response.response.menu);
        const packRoots = ProductNode.parseArray(response.response.pack_roots.map(o => ({ ...o, pack_only: true })));
        return {
            packRoots,
            menu: ProductNode._addChildren(menu, packRoots),
            status: 'ok',
        }
    }
    throw service.error(response);
}

export const getPacks = async () => {
    const response = await service.get(`/pickupbars/${service.bar_id}${PREFIX}/packs/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            packs: ProductPack.parseArray(response.response)
        }
    }
    throw service.error(response);
}

export const create = async (data) => {
    const response = await service.post(`/pickupbars/${service.bar_id}${PREFIX}/`, {
        selections: data.selections,
        amount_off: data.amountOff,
        service_type: data.serviceType,
        pickupbar: service.bar_id
    });
    if (response.status === 'ok') {
        return {
            status: 'ok',
            order: Order.parse(response.response)
        }
    }
    throw service.error(response);
}

export const markAsPaid = async (pk) => {
    const response = await service.post(`/pickupbars/${service.bar_id}${PREFIX}/${pk}/mark_as_paid/`);
    if (response.status === 'ok') {
        return {
            status: 'ok',
            order: Order.parse(response.response)
        }
    }
    throw service.error(response);
}

export const cancel = async (pk) => {
    const response = await service.delete(`/pickupbars/${service.bar_id}${PREFIX}/${pk}/`);
    if (response.status === 'ok') {
        return {
            status: 'ok'
        }
    }
    throw service.error(response);
}