import { combineReducers } from 'redux';
import { PickupBar, ProductNode, Order } from '@datamodel';
import { mapObjectValues } from '@utils/helpers';
import {
    EXIT_BAR,
    SET_ACTIVE_SALES,
    RECEIVE_ORDERS,
    RECEIVE_MENUS, RECEIVE_PICKUP_BARS, SELECT_BAR,
    RECEIVE_GRILL_ORDERS,
    SET_GRILL_ORDER_SERVING_STATUS,
    READ_QR,
    CLEAR_READING_SESSION,
    SET_READ_ORDER_MODAL_VISIBLE,
    SIGN_OUT,
    SET_LAST_MENU,
    ADD_BATCH,
    WRITE_SETTINGS,
    CLEAN_HISTORY_CURSOR
} from './actions';


const authentication = (authentication = {
    isAuth: false,
    pickupBarId: null,
    placeName: '',
    pickupBarName: '',
    token: null
}, action) => {
    switch (action.type) {
        case SELECT_BAR:
            return {
                ...authentication,
                isAuth: true,
                pickupBarId: action.pickupBarId,
                pickupBarName: action.pickupBarName,
                placeName: action.placeName, manualLogOut: false 
            }
        case RECEIVE_PICKUP_BARS:
            return {
                ...authentication,
                token: action.token, manualLogOut: false 
            };
        case EXIT_BAR:
            return { ...authentication, isAuth: false, pickupBarId: null, manualLogOut: true }
        case SIGN_OUT:
            return { ...authentication, isAuth: false, pickupBarId: null, token: null, manualLogOut: false }
        default:
            return authentication;
    }
}

// const drafts = (drafts = [], action) => {
//     switch (action.type) {
//         case SAVE_DRAFT:
//             return [...drafts, { createdAt: JSON.stringify(action.createdAt), serverCart: action.serverCart, id: action.id, menu: action.menu, totalPrice: action.totalPrice }]
//         case REMOVE_DRAFT:
//             return drafts.filter(d => d.id !== action.id);
//         case EXIT_BAR:
//             return [];
//         default:
//             return drafts;
//     }
// }

const history = (history = { orders: {}, previous: null, next: null }, action) => {
    switch (action.type) {
        case RECEIVE_ORDERS:
            return {
                orders: {
                    ...history.orders,
                    ...action.orders
                },
                previous: action.previous,
                next: action.next
            };
        case CLEAN_HISTORY_CURSOR:
            return {
                ...history,
                previous: null,
                next: null
            }
        case EXIT_BAR:
            return { orders: {}, previous: null, next: null };
        default:
            return history;
    }
}

const menus = (menus = {}, action) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            if (action.payload) {
                return mapObjectValues(action.payload.menus, p => ProductNode.unserialize(p));
            }
            return menus;

        case SET_LAST_MENU:
            if ( !(action.lastMenu.nodeId in menus ) ) {
                return { ...menus, [action.lastMenu.nodeId]: JSON.parse(JSON.stringify(action.lastMenu)) }
            }
            return menus;

        case RECEIVE_MENUS:
            // Flatten the nodes
            const flatMenus = mapObjectValues(
                action.menus,
                p => JSON.parse(JSON.stringify(p)),
            )

            return { ...menus, ...flatMenus };

        case EXIT_BAR:
            return {};
        default:
            return menus;
    }
}


const pickupBars = (pickupBars = {}, action) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            if (action.payload && pickupBars in action.payload) {
                const rehydrated = mapObjectValues(
                    action.payload.pickupBars,
                    p => PickupBar.unserialize(p)
                )
                return rehydrated;
            }
            return pickupBars;

        case SET_ACTIVE_SALES:
            const pBar = pickupBars[action.pickupBarId];
            pBar.place.menuSalesAvailable = action.value;
            return {
                ...pickupBars,
                [action.pickupBarId] : {...pBar}
            }

        case RECEIVE_PICKUP_BARS:
            return action.pickupBars;

        default:
            return pickupBars;
    }
}

const preparationGroups = (preparationGroups = {}, action) => {
    switch (action.type) {
        case SELECT_BAR:
            return action.preparationGroups;
        case EXIT_BAR:
        case SIGN_OUT:
            return {};
        default:
            return preparationGroups;
    }
}

const grillOrders = (grillOrders = {
    [Order.SERVING_PENDING]: {},
    [Order.SERVING_PREPARING]: {},
    [Order.SERVING_READY]: {},
}, action) => {
    switch (action.type) {
        case SET_GRILL_ORDER_SERVING_STATUS:
            const { orderId, status, currentStatus } = action;
            const { [orderId]: order, ...newCurrent } = grillOrders[currentStatus];
            order.servingStatus = status;
            order.timestamp = action.timestamp;
            return {
                ...grillOrders,
                [currentStatus]: {...newCurrent},
                [status]: {...grillOrders[status], [orderId]: order}
            }

        case RECEIVE_GRILL_ORDERS:
            // Flatten the object
            const flatten = {
                ...grillOrders[Order.SERVING_PENDING],
                ...grillOrders[Order.SERVING_PREPARING],
                ...grillOrders[Order.SERVING_READY]
            }
            
            const newGrill = mapObjectValues(action.orders, (order, orderId) => {
                if ( !flatten[orderId] || action.timestamp > ( flatten[orderId].timestamp || 0) ) {
                    return order;
                } else {
                    return flatten[orderId];
                }
            });

            // Refresh with new object when available
            return Order.stackByServingStatus(newGrill);

        default:
            return grillOrders;
    }
}

const grillBatches = (grillBatches = {
    pickupBarId: '', // Should be eventId?
    batches: [],
    orderIds: {},
    /* orderIds is a dict containing each orderId that has been added to a batch.
        In this sense it is derived data from the batches list, however, it will be updated
        along with the batches list and provides a fast way of computing wether a pending order is present
        in a batch or not.
    */
}, action) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            if (action.payload && grillBatches in action.payload) {
                return action.payload.grillBatches;
            }
            return grillBatches;

        case ADD_BATCH:
            if (action.pickupBarId !== grillBatches.pickupBarId) {
                // Batch comes from a new pickupBarId, reset batches
                return {
                    pickupBarId: action.pickupBarId,
                    batches: action.batch ? [ action.batch ] : [],
                    orderIds: action.batch ?
                        Object.fromEntries(Object.keys(action.batch).map(orderId => [orderId, 0])) : {}
                };
            }
            return {
                ...grillBatches,
                batches: [
                    ...grillBatches.batches,
                    action.batch
                ],
                orderIds: {
                    ...grillBatches.orderIds,
                    ...(Object.fromEntries(Object.keys(action.batch).map(orderId => [orderId, grillBatches.batches.length])))
                }
            };
        default:
            return grillBatches;
    }
}

const readingSession = (readingSession = { order: {}, purchases: {}, visible: false }, action) => {
    switch (action.type) {
        case READ_QR:
            return {
                ...readingSession,
                purchases: {
                    ...readingSession.purchases,
                    ...action.purchases
                },
                order: action.order,
                purchaseGroup: action.purchaseGroup
            }
        case SET_READ_ORDER_MODAL_VISIBLE:
            return {
                ...readingSession, visible: action.value
            }
        case CLEAR_READING_SESSION:
        case SELECT_BAR:
        case EXIT_BAR:
            return { order: {}, purchases: {}, visible: false }

        default:
            return readingSession;
    }
}

const lastMenu = (lastMenu = {}, action) => {
    switch (action.type) {
        case SET_LAST_MENU:
            return action.lastMenu;
        default:
            return lastMenu;
    }
}

const lastPacks = (lastPacks = {}, action) => {
    switch (action.type) {
        case SET_LAST_MENU:
            return action.lastPacks;
        default:
            return lastPacks;
    }
}

const lastPromotions = (lastPromotions = {}, action) => {
    switch (action.type) {
        case SET_LAST_MENU:
            return action.lastPromotions;
        default:
            return lastPromotions;
    }
}

const settings = (settings={
    collapsedNavigation: false
}, action) => {
    switch (action.type) {
        case WRITE_SETTINGS:
            return {
                ...settings,
                ...action.settings
            }
        default:
            return settings;
    }
}

export default combineReducers({ settings, authentication, history, menus, pickupBars, grillOrders, readingSession, lastMenu, lastPacks, lastPromotions, preparationGroups, grillBatches });

// export const rootReducer = defaultReducer;
