export const printIframeAsync = (iframe) => new Promise((resolve, reject) => {
    if (!iframe || !iframe.contentWindow) {
        reject();
        return;
    }

    const iwindow = iframe.contentWindow;
    const endPrint = () => {
        resolve();
    }

    window.addEventListener('focus', endPrint, { once: true });
    iwindow.print();
})